import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Icon } from 'Components/Icons';
import { EquipmentCount } from 'Components/RocketDry';
import { RoomEquipment } from 'Containers/RocketDry/types';
import { PurpleButton } from 'Components/Button';
import { TextBox } from 'Components/TextBox';

import { trimAndToLowerCase } from 'Utils/helpers';

import DatePicker from 'react-datepicker';

import formClasses from 'Themes/form/form.module.css';
import classes from './equipmentInfo.module.css';

interface Props {
  equipment: RoomEquipment;
  showEditComponent: boolean;
  quantity: any;
  dayIn: any;
  dayOut: any;
  onEditClick: (e: any) => void;
  onDeleteClick: (e: any) => void;
  onChangeQuantity: (e: any) => void;
  onChangeDate: (e: any) => void;
  onSaveButtonClick: (e: any) => void;
  onCancelButtonClick: (e: any) => void;
}

function convertDate(date: String): String {
  const splitDate = date.split('-');
  splitDate[2] = splitDate[2].split('T')[0];
  splitDate.reverse();
  return splitDate.join('-');
}

function EquipmentInfo({
  equipment,
  showEditComponent,
  quantity,
  dayIn,
  dayOut,
  onEditClick,
  onDeleteClick,
  onChangeQuantity,
  onChangeDate,
  onSaveButtonClick,
  onCancelButtonClick,
}: Props) {
  return (
    <div>
      <div className={classes.itemsRow}>
        <div className={classes.leftItems}>
          <EquipmentCount
            name={equipment.display_name}
            icon={<Icon type={trimAndToLowerCase(equipment.name)} />}
            count={equipment.quantity}
          />
          <div className={classes.innerContainer}>X</div>
        </div>
        <div className={`${classes.innerContainer} ${classes.durationContainer}`}>
          {equipment.date_in && (
            <span>
              Day IN:
              {` ${convertDate(equipment.date_in)}`}
            </span>
          )}
          {equipment.date_out && (
            <span>
              Day OUT:
              {` ${convertDate(equipment.date_out)}`}
            </span>
          )}
          <span>{equipment.duration} Days</span>
          <span role="button" className={classes.editText} onClick={onEditClick} onKeyUp={onEditClick} tabIndex={0}>
            Edit
          </span>
        </div>
        <Icon type="trash" onClick={onDeleteClick} />
      </div>
      {showEditComponent && (
        <div className={classes.editContainer}>
          <div className={classes.equipmentDateContainer}>
            <div className={classes.inline}>
              Quantity:{' '}
              <TextBox
                className={`mb-0 pb-0 ${formClasses.validateField} ${classes.quantity}`}
                name="quantity"
                type="number"
                min={1}
                max={999}
                ariaLabel="equipment quantity"
                value={quantity}
                onChange={onChangeQuantity}
              />
            </div>
            <br />
            <div>
              Start:
              {dayIn ? convertDate(equipment.date_in) : ''}
            </div>
            <br />
            <div>
              End:
              {dayOut ? convertDate(equipment.date_in) : ''}
            </div>
          </div>

          <div className={classes.datePickerContainer}>
            <DatePicker
              todayButton="Today"
              selected={dayIn}
              onChange={onChangeDate}
              startDate={dayIn}
              endDate={dayOut}
              selectsRange
              inline
              isClearable
            />
          </div>

          <div className={classes.buttonContainer}>
            <PurpleButton onClick={onSaveButtonClick} id="date">
              Save Changes
            </PurpleButton>
            <br />
            <PurpleButton onClick={onCancelButtonClick} id="date">
              Cancel
            </PurpleButton>
          </div>
        </div>
      )}
    </div>
  );
}

const EquipmentInfoMemo = memo(EquipmentInfo, areEqual);

export { EquipmentInfoMemo as EquipmentInfo };
