import React, { memo, useCallback } from 'react';
import DataGrid, { TextEditor } from 'react-data-grid';
import { Tab } from 'Components/Tabs/Tab';
import { areEqual } from 'Utils/equalityChecks';
import { ScopeData } from 'Containers/RocketScope/types';
import classes from './rocketScopeDataGrid.module.css';

interface Props {
  sheetNames: string[];
  scopeData: ScopeData[][];
  currentSheet: number;
  setCurrentSheet: (sheet: number) => void;
  onRowsChange?: (newRows: ScopeData[]) => void;
  width: number;
  addSheet?: () => void;
  onSelectionChange?: (selectedRow: number | null) => void;
  selectedRow: number | null;
}

const RocketScopeDataGrid = ({
  sheetNames,
  scopeData,
  currentSheet,
  setCurrentSheet,
  onRowsChange,
  width,
  onSelectionChange,
  addSheet,
  selectedRow,
}: Props) => {
  const rowClickHandler = useCallback((row: number): void => {
    if (onSelectionChange) {
      onSelectionChange(row);
    }
  }, []);

  const isSelectedRow = (row: ScopeData): boolean =>
    selectedRow !== null && row === scopeData[currentSheet][selectedRow];

  const isInvalidRow = (row: ScopeData): boolean =>
    !isSelectedRow(row) && (row.category === '' || row.unit === '' || row.description === '');

  return (
    <>
      <div className={`${classes.showSheetSelector} ${sheetNames.length > 1 ? '' : classes.hidden}`}>
        <ul className={`nav nav-tabs pr-4 ${width < 576 ? 'flex-sm-column' : 'width'} border-bottom-0`}>
          {sheetNames.map((sheetName, tab) => (
            <Tab
              key={sheetName}
              className={`${classes.flexCenter} ${currentSheet === tab ? `active ${classes.activeTab}` : ''}`}
              onClick={() => setCurrentSheet(tab)} // limited usage, not recommend for reusable components
            >
              <span>{sheetName}</span>
            </Tab>
          ))}
          {addSheet && (
            <Tab className={`${classes.flexCenter} ${classes.addSheet}`} onClick={addSheet}>
              <span>+ Add Tab</span>
            </Tab>
          )}
        </ul>
      </div>
      {sheetNames.length > 0 && (
        <DataGrid
          className="rdg-light"
          onRowsChange={onRowsChange}
          rows={scopeData[currentSheet]}
          onRowClick={rowClickHandler}
          rowClass={(row) => {
            if (isInvalidRow(row)) {
              return classes.invalidRow;
            }
            return '';
          }}
          columns={[
            {
              key: 'category',
              name: 'Category',
              width: 120,
              editor: onRowsChange ? TextEditor : null,
              cellClass: (row) => (!isSelectedRow(row) && row.category === '' ? classes.invalidCell : ''),
            },
            {
              key: 'codePart1',
              name: 'Code Part 1',
              width: 100,
              editor: onRowsChange ? TextEditor : null,
            },
            {
              key: 'codePart2',
              name: 'Code Part 2',
              width: 100,
              editor: onRowsChange ? TextEditor : null,
            },
            {
              key: 'description',
              name: 'Description',
              editor: onRowsChange ? TextEditor : null,
              cellClass: (row) => (!isSelectedRow(row) && row.description === '' ? classes.invalidCell : ''),
            },
            {
              key: 'unit',
              name: 'Unit',
              width: 50,
              editor: onRowsChange ? TextEditor : null,
              cellClass: (row) => (!isSelectedRow(row) && row.unit === '' ? classes.invalidCell : ''),
            },
            {
              key: 'rate',
              name: 'Rate',
              width: 70,
              editor: onRowsChange ? TextEditor : null,
            },
            {
              key: 'notes',
              name: 'Notes',
              editor: onRowsChange ? TextEditor : null,
            },
          ]}
          style={{ height: 'calc(100vh - 400px)' }}
        />
      )}
    </>
  );
};

const RocketScopeDataGridMemo = memo(RocketScopeDataGrid, areEqual);
export { RocketScopeDataGridMemo as RocketScopeDataGrid };
