import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { PurpleButton } from 'Components/Button';
import { Modal } from 'Components/Modal';

import classes from './chooseReportTypeModal.module.css';

interface Props {
  isOpen: boolean;
  useCustomButtons?: boolean;
  modalCloseClick: (e: any) => void;
  onPhotoReportClick: (e: any) => void;
  onCustomReportClick: (e: any) => void;
}
function ChooseReportTypeModal({
  isOpen,
  useCustomButtons,
  modalCloseClick,
  onPhotoReportClick,
  onCustomReportClick,
}: Props) {
  return (
    <Modal
      id="affected-location-modal"
      classes={classes}
      title="Drying, Photo or Custom Report?"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      <div className={classes.text}>Please select which report type you want to generate:</div>
      <div className={classes.buttonWrapper}>
        <PurpleButton className={classes.reportTypeButton} onClick={onPhotoReportClick}>
          Rocket Report
        </PurpleButton>
        {useCustomButtons || (
          <PurpleButton className={classes.reportTypeButton} onClick={onCustomReportClick}>
            Custom Report
          </PurpleButton>
        )}
      </div>
      {useCustomButtons && (
        <div className={classes.buttonWrapper}>
          <PurpleButton
            className={`${classes.reportTypeButton} ${classes.smallerButtonText}`}
            onClick={onCustomReportClick}
          >
            Disaster Specimen
          </PurpleButton>
          <PurpleButton className={classes.reportTypeButton}>Sample Report</PurpleButton>
        </div>
      )}
    </Modal>
  );
}

ChooseReportTypeModal.defaultProps = {
  useCustomButtons: undefined,
};

const ChooseReportTypeModalMemo = memo(ChooseReportTypeModal, areEqual);

export { ChooseReportTypeModalMemo as ChooseReportTypeModal };
