import React, { memo, useCallback, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { PhotoReportForm, GenerateReportWrapper } from 'Components/ReportsAndDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { createReport, setReportCreated } from 'Containers/ReportsAndDocuments/Reports/actions';
import { projectSelector, projectAliasSelector } from 'Containers/RocketScan/selectors';
import {
  reportCreatedSelector,
  reportCreatingSelector,
  selectedReportLocationsSelector,
  selectedReportRoomsSelector,
  selectedReportScopeOfWorkSelector,
  titleErrorSelector,
} from 'Containers/ReportsAndDocuments/Reports/selectors';
import { useHistory } from 'react-router-dom';

import { useUser } from 'Context/User';
import type { UserModel } from 'Containers/User/Models/UserModel';
import { userFeatureFlagsSelector } from 'Containers/User/selector';
import { CompanyInfo, ReportSettings } from 'Types/reports';

function PhotoReportContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const user: UserModel = useUser();

  const project = useSelector(projectSelector, areEqual);
  const fetching = useSelector(reportCreatingSelector, areEqual);
  const reportCreated = useSelector(reportCreatedSelector, areEqual);
  const selectedLocations = useSelector(selectedReportLocationsSelector, areEqual);
  const selectedRooms = useSelector(selectedReportRoomsSelector, areEqual);
  const selectedScopeOfWork = useSelector(selectedReportScopeOfWorkSelector, areEqual);
  const alias = useSelector(projectAliasSelector, areEqual);

  const { companies }: any = user;
  const [company] = companies;
  const {
    name: companyName,
    logo_url: logoUrl,
    secondary_name: secondaryCompanyName,
    secondary_logo_url: secondaryLogoUrl,
  } = company;

  const { secondaryCompany: showSecondaryCompanyOption } = useSelector(userFeatureFlagsSelector, areEqual);

  const [name, setName] = useState('');
  const [useSecondaryCompany, setUseSecondaryCompany] = useState('no');

  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(new Date().getDate() - 31);

  const [reportSettings, setReportSettings] = useState<ReportSettings>({
    format: 'compact',
    unitType: 'full_project',
    photosType: 'all',
    notesType: 'all',
    photoNotesType: 'all',
    damagedMaterials: 'no',
    scopeOfWork: 'yes',
    scopeOfWorkCost: 'yes',
    sketchVirtualTour: 'yes',
    dateRange: 'all',
    startDate: oneMonthAgo,
    endDate: new Date(),
    externalAtmosphericData: 'yes',
    internalAtmosphericData: 'yes',
    moistureLogs: 'yes',
    rocketDryPhotos: 'yes',
    equipmentList: 'yes',
    eSignatureAndForms: 'no',
    uploadedDocuments: 'no',
  });

  const companyInfo: CompanyInfo = {
    name: companyName,
    logo: logoUrl,
    secondaryName: secondaryCompanyName,
    secondaryLogo: secondaryLogoUrl,
    useSecondary: useSecondaryCompany === 'yes',
    showSecondaryOption: showSecondaryCompanyOption,
  };

  const [propertyData, setPropertyData] = useState({
    projectNumber: true,
    projectAlias: true,
    projectType: true,
    referredBy: true,
    phone: true,
    platinumAgent: true,
    propertyClassification: true,
    asbestosStatus: true,
    yearBuilt: true,
    buildingName: true,
  });

  const [lossData, setLossData] = useState({
    typeOfDamage: true,
    causeOfDamage: true,
    category: true,
    class: true,
    dateOfLoss: true,
    callReceived: true,
    dispatchTime: true,
    arrivalTime: true,
  });

  const [claims, setClaims] = useState({
    policyNumber: true,
    representative: true,
    contactStatus: true,
    phone: true,
    email: true,
    provider: true,
    deductible: true,
    claimNumber: true,
    adjusterName: true,
    adjusterPhone: true,
    adjusterEmail: true,
  });

  const errors = {
    title: useSelector(titleErrorSelector, areEqual),
  };

  const onChangeTitle = useCallback(({ target: { value } }: any) => {
    setName(value);
  }, []);

  const onCompanyClick = useCallback(({ currentTarget: { value } }: any) => {
    setUseSecondaryCompany(value);
  }, []);

  // Grouped handlers
  const onSettingsChange = {
    onSelectFormat: useCallback(({ currentTarget: { id } }: any) => {
      setReportSettings((prev) => ({ ...prev, format: id }));
    }, []),
    onUnitTypeClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, unitType: value }));
    }, []),
    onPhotosTypeClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, photosType: value }));
    }, []),
    onNotesTypeClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, notesType: value }));
    }, []),
    onPhotoNotesTypeClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, photoNotesType: value }));
    }, []),
    onDamagedMaterialsClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, damagedMaterials: value }));
    }, []),
    onScopeOfWorkClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, scopeOfWork: value }));
    }, []),
    onScopeOfWorkCostClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, scopeOfWorkCost: value }));
    }, []),
    onSketchVirtualTourClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, sketchVirtualTour: value }));
    }, []),
    onDateRangeClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, dateRange: value }));
    }, []),
    onDateRangeChange: useCallback((dates: [Date | null, Date | null]) => {
      const [startDate, endDate] = dates;
      setReportSettings((prev) => ({ ...prev, startDate, endDate }));
    }, []),
    onRocketDryClick: useCallback((field: string) => {
      setReportSettings((prev) => ({
        ...prev,
        [field]: prev[field] === 'yes' ? 'no' : 'yes',
      }));
    }, []),
    onESignatureAndFormsClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, eSignatureAndForms: value }));
    }, []),
    onUploadedDocumentsClick: useCallback(({ currentTarget: { value } }: any) => {
      setReportSettings((prev) => ({ ...prev, uploadedDocuments: value }));
    }, []),
  };

  const onPropertyDataChange = useCallback((field: string) => {
    setPropertyData((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  }, []);

  const onLossDataChange = useCallback((field: string) => {
    setLossData((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  }, []);

  const onClaimsChange = useCallback((field: string) => {
    setClaims((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  }, []);

  const onToggleAllPropertyData = useCallback(() => {
    const allChecked = Object.values(propertyData).every((value) => value);
    setPropertyData((prev) => {
      const newState = { ...prev };
      Object.keys(newState).forEach((key) => {
        newState[key] = !allChecked;
      });
      return newState;
    });
  }, [propertyData]);

  const onToggleAllLossData = useCallback(() => {
    const allChecked = Object.values(lossData).every((value) => value);
    setLossData((prev) => {
      const newState = { ...prev };
      Object.keys(newState).forEach((key) => {
        newState[key] = !allChecked;
      });
      return newState;
    });
  }, [lossData]);

  const onToggleAllClaims = useCallback(() => {
    const allChecked = Object.values(claims).every((value) => value);
    setClaims((prev) => {
      const newState = { ...prev };
      Object.keys(newState).forEach((key) => {
        newState[key] = !allChecked;
      });
      return newState;
    });
  }, [claims]);

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (project?.id) {
        const { id: projectId } = project;

        const settings = JSON.stringify({
          format: reportSettings.format,
          unit_type: reportSettings.unitType,
          photos: reportSettings.photosType,
          notes: reportSettings.notesType,
          photo_notes: reportSettings.photoNotesType,
          damaged_materials: 'no', // For now we hard-coding damaged_materials to no reportSettings.damagedMaterials,
          use_secondary_company: useSecondaryCompany,
          locations: reportSettings.unitType === 'full_project' ? [] : selectedLocations,
          rooms: reportSettings.unitType === 'full_project' ? [] : selectedRooms,
          selected_scope_of_work: reportSettings.scopeOfWork === 'specific' ? selectedScopeOfWork : [],
          scope_of_work: reportSettings.scopeOfWork,
          scope_of_work_cost: reportSettings.scopeOfWorkCost,
          sketch_virtual_tour: reportSettings.sketchVirtualTour,
          date_range: reportSettings.dateRange,
          start_date: reportSettings.startDate,
          end_date: reportSettings.endDate,
          atmospheric_external: reportSettings.externalAtmosphericData,
          atmospheric_internal: reportSettings.internalAtmosphericData,
          moisture_logs: reportSettings.moistureLogs,
          rocket_dry_photos: reportSettings.rocketDryPhotos,
          equipment_list: reportSettings.equipmentList,
          e_signature_and_forms: reportSettings.eSignatureAndForms,
          uploaded_documents: reportSettings.uploadedDocuments,
          property_data: propertyData,
          loss_data: lossData,
          claims,
        });

        localStorage.setItem('reportSettings', settings);

        dispatch(createReport(projectId, { name, settings }, alias, user));
      }
    },
    [
      name,
      project,
      reportSettings,
      useSecondaryCompany,
      selectedLocations,
      selectedRooms,
      propertyData,
      lossData,
      claims,
    ]
  );

  useEffect(() => {
    if (reportCreated) {
      const { id: projectId } = project;

      history.push(`/projects/${projectId}/rocketreports`);
    }

    return () => {
      if (reportCreated) {
        dispatch(setReportCreated(false));
      }
    };
  }, [reportCreated, project]);

  // useEffect(() => {
  //   const localSettings = localStorage.getItem('reportSettings');
  //   if (localSettings) {
  //     const settings = JSON.parse(localSettings);
  //     setReportSettings((prev) => ({
  //       ...prev,
  //       format: settings.format,
  //       unitType: settings.unit_type,
  //       photosType: settings.photos,
  //       notesType: settings.notes,
  //       photoNotesType: settings.photo_notes,
  //       damagedMaterials: settings.damaged_materials,
  //       scopeOfWork: settings.scope_of_work,
  //       scopeOfWorkCost: settings.scope_of_work_cost,
  //       sketchVirtualTour: settings.sketch_virtual_tour,
  //       dateRange: settings.date_range,
  //       externalAtmosphericData: settings.atmospheric_external,
  //       internalAtmosphericData: settings.atmospheric_internal,
  //       moistureLogs: settings.moisture_logs,
  //       rocketDryPhotos: settings.rocket_dry_photos,
  //       equipmentList: settings.equipment_list,
  //       eSignatureAndForms: settings.e_signature_and_forms,
  //       uploadedDocuments: settings.uploaded_documents,
  //     }));
  //     setPropertyData(settings.property_data);
  //     setLossData(settings.loss_data);
  //     setClaims(settings.claims);
  //   }
  // }, []);

  return (
    <GenerateReportWrapper>
      <PhotoReportForm
        title={name}
        companyInfo={companyInfo}
        reportSettings={reportSettings}
        propertyData={propertyData}
        lossData={lossData}
        claims={claims}
        projectStartDate={project ? new Date(project.createdAt) : new Date(0)}
        formErrors={errors}
        fetching={fetching}
        onChangeTitle={onChangeTitle}
        onCompanyClick={onCompanyClick}
        onSettingsChange={onSettingsChange}
        onPropertyDataChange={onPropertyDataChange}
        onLossDataChange={onLossDataChange}
        onClaimsChange={onClaimsChange}
        onFormSubmit={onFormSubmit}
        onToggleAllPropertyData={onToggleAllPropertyData}
        onToggleAllLossData={onToggleAllLossData}
        onToggleAllClaims={onToggleAllClaims}
      />
    </GenerateReportWrapper>
  );
}

const PhotoReportContainerMemo = memo(PhotoReportContainer, areEqual);

export { PhotoReportContainerMemo as PhotoReport };
