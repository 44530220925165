import React, { memo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddExternalAtmosphericLogModal } from 'Components/RocketDry';

import { useRocketDryFunctions } from 'Context/RocketDry';

import { addRoomExternalAtmosphericLogs } from 'Containers/RocketDry/actions';
import { createExternalAtmosphericLogErrorsSelector } from 'Containers/RocketDry/selectors';
import { areEqual } from 'Utils/equalityChecks';

const numberInputRegex = /[^\-.0-9]/g;

function AddExternalAtmosphericLogModalContainer() {
  const dispatch = useDispatch();

  const { project, isAddExternalAtmosphericLogModalOpen, setIsAddExternalAtmosphericLogModalOpen }: any =
    useRocketDryFunctions();

  const createExternalAtmosphericLogErrors = useSelector(createExternalAtmosphericLogErrorsSelector, areEqual);

  const [relativeHumidity, setRelativeHumidity] = useState('');
  const [temperature, setTemperature] = useState('');
  const [pressure, setPressure] = useState('');
  const [windSpeed, setWindSpeed] = useState('');

  const onChangeRelativeHumidity = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setRelativeHumidity(filtered);
  }, []);

  const onChangeTemperature = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setTemperature(filtered);
  }, []);

  const onChangePressure = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setPressure(filtered);
  }, []);

  const onChangeWindSpeed = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setWindSpeed(filtered);
  }, []);

  const onCloseModal = useCallback(() => {
    setIsAddExternalAtmosphericLogModalOpen(false);
    setRelativeHumidity('');
    setTemperature('');
    setPressure('');
    setWindSpeed('');
  }, []);

  const onSaveExternalButtonClick = useCallback(() => {
    if (project?.id) {
      dispatch(
        addRoomExternalAtmosphericLogs(project.id, relativeHumidity, temperature, pressure, windSpeed, onCloseModal)
      );
    }
  }, [project, relativeHumidity, temperature, pressure, windSpeed]);

  return (
    <AddExternalAtmosphericLogModal
      isOpen={isAddExternalAtmosphericLogModalOpen}
      closeModal={onCloseModal}
      relativeHumidity={relativeHumidity}
      temperature={temperature}
      pressure={pressure}
      windSpeed={windSpeed}
      formErrors={createExternalAtmosphericLogErrors}
      onSaveExternalButtonClick={onSaveExternalButtonClick}
      onChangeRelativeHumidity={onChangeRelativeHumidity}
      onChangePressure={onChangePressure}
      onChangeTemperature={onChangeTemperature}
      onChangeWindSpeed={onChangeWindSpeed}
    />
  );
}

const AddExternalAtmosphericLogModalMemo = memo(AddExternalAtmosphericLogModalContainer);

export { AddExternalAtmosphericLogModalMemo as AddExternalAtmosphericLogModal };
