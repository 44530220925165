import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { PurpleButton } from 'Components/Button';
import { Modal } from 'Components/Modal';

import classes from './chooseReportTypeModalAllDry.module.css';

interface Props {
  isOpen: boolean;
  modalCloseClick: (e: any) => void;
  onPhotoReportClick: (e: any) => void;
  onCustomReportClick: (e: any) => void;
  onCustomReportClickAllDry: (e: any) => void;
}
function ChooseReportTypeModalAllDry({
  isOpen,
  modalCloseClick,
  onPhotoReportClick,
  onCustomReportClick,
  onCustomReportClickAllDry,
}: Props) {
  return (
    <Modal
      id="affected-location-modal"
      classes={classes}
      title="Drying, Photo or Custom Report?"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      <div className={classes.text}>Please select which report type you want to generate:</div>
      <div className={classes.buttonWrapper}>
        <PurpleButton className={classes.reportTypeButton} onClick={onPhotoReportClick}>
          Rocket Report
        </PurpleButton>
      </div>
      <div className={classes.buttonWrapper}>
        <PurpleButton className={classes.reportTypeButton} onClick={onCustomReportClick}>
          Custom Report
        </PurpleButton>
        <PurpleButton className={classes.reportTypeButton} onClick={onCustomReportClickAllDry}>
          All Dry Template
        </PurpleButton>
      </div>
    </Modal>
  );
}

const ChooseReportTypeModalAllDryMemo = memo(ChooseReportTypeModalAllDry, areEqual);

export { ChooseReportTypeModalAllDryMemo as ChooseReportTypeModalAllDry };
