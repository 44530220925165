import React, { memo, useState, useCallback } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';

import { AddRoomEquipmentModal } from 'Components/RocketDry';

import { projectEquipmentListSelector } from 'Containers/RocketDry/selectors';

import { useRocketDryFunctions } from 'Context/RocketDry';

import { addEquipmentToRoom } from 'Containers/RocketDry/actions';

import { addOrRemoveFromArray } from 'Utils/helpers';

function AddRoomEquipmentModalContainer() {
  const dispatch = useDispatch();

  const { isAddEquipmentModalOpen, setIsAddEquipmentModalOpen, selectedRoomId, setEquipmentAdded }: any =
    useRocketDryFunctions();

  const projectEquipmentList = useSelector(projectEquipmentListSelector, areEqual);

  const [selectedEquipmentIDs, setSelectedEquipmentIDs] = useState([]);

  const onCloseModal = useCallback(() => {
    setIsAddEquipmentModalOpen(false);
    setSelectedEquipmentIDs([]);
  }, []);

  const onClickEquipment = useCallback((id) => {
    setSelectedEquipmentIDs((prevIds) => addOrRemoveFromArray(prevIds, id));
  }, []);

  const onSaveButtonClick = useCallback(async () => {
    if (selectedRoomId) {
      const response: any = await dispatch(addEquipmentToRoom(selectedRoomId, selectedEquipmentIDs));

      if (response?.data) {
        setEquipmentAdded(true);
        onCloseModal();
      }
    }
  }, [selectedRoomId, selectedEquipmentIDs]);

  return (
    <AddRoomEquipmentModal
      isOpen={isAddEquipmentModalOpen}
      equipmentList={projectEquipmentList}
      selectedEquipmentIDs={selectedEquipmentIDs}
      onClickEquipment={onClickEquipment}
      closeModal={onCloseModal}
      onSaveButtonClick={onSaveButtonClick}
    />
  );
}

const AddRoomEquipmentModalContainerMemo = memo(AddRoomEquipmentModalContainer, areEqual);

export { AddRoomEquipmentModalContainerMemo as AddRoomEquipmentModal };
