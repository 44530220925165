import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { MoistureAtmosphericProvider } from 'Context/MoistureAtmospheric';
import { MoistureAtmospheric } from 'Components/RocketDry';
import {
  ViewAtmosphericLogModal,
  ViewMoistureLogModal,
  AddExternalAtmosphericLogModal,
  AddInternalAtmosphericLogModal,
} from 'Containers/RocketDry';

function MoistureAtmosphericWrapper() {
  return (
    <MoistureAtmosphericProvider>
      <MoistureAtmospheric />
      <AddExternalAtmosphericLogModal />
      <AddInternalAtmosphericLogModal />
      <ViewAtmosphericLogModal />
      <ViewMoistureLogModal />
    </MoistureAtmosphericProvider>
  );
}

const MoistureAtmosphericWrapperrMemo = memo(MoistureAtmosphericWrapper, areEqual);

export { MoistureAtmosphericWrapperrMemo as MoistureAtmosphericWrapper };
