import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { PurpleButton } from 'Components/Button';
import { NoEquipmentPlaceholder } from 'Components/RocketDry';
import { EquipmentInfo } from 'Containers/RocketDry/EquipmentLog/EquipmentInfo';

import classes from './locationEquipment.module.css';

interface Props {
  equipmentList: any;
  onAddButtonClick: (e: any) => void;
}

function LocationEquipment({ equipmentList, onAddButtonClick }: Props) {
  return equipmentList?.length > 0 ? (
    <>
      <div className={classes.container}>
        {equipmentList.map((equipment) => (
          <EquipmentInfo key={equipment.id} equipment={equipment} />
        ))}
      </div>
      <br />
      <PurpleButton onClick={onAddButtonClick}>Add Equipment</PurpleButton>
    </>
  ) : (
    <NoEquipmentPlaceholder onAddButtonClick={onAddButtonClick} />
  );
}

const LocationEquipmentMemo = memo(LocationEquipment, areEqual);

export { LocationEquipmentMemo as LocationEquipment };
