import React, { memo, useCallback, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';

import { ExternalAtmosphericTable } from 'Components/RocketDry';
import { SpinnerBlock } from 'Components/SpinnerBlock';

import { externalAtmosphericTableHeaders } from 'Utils/table';

import { getProjectAtmosphericLogsWithPhoto, setExternalAtmosphericLogCreated } from 'Containers/RocketDry/actions';

import { projectSelector } from 'Containers/RocketScan/selectors';
import { projectAtmosphericLogsSelector, externalAtmosphericLogsCreatedSelector } from 'Containers/RocketDry/selectors';
import { atmosphericLogType } from 'Containers/RocketDry/types';
import { useRocketDryFunctions } from 'Context/RocketDry';

function ExternalAtmosphericTableContainer() {
  const dispatch = useDispatch();

  const {
    setSelectedAtmosphericLog,
    setSelectedAtmosphericLogType,
    setIsViewAtmosphericLogModalOpen,
    setIsAddExternalAtmosphericLogModalOpen,
  }: any = useRocketDryFunctions();

  // selectors
  const project = useSelector(projectSelector, areEqual);
  const atmosphericLogs = useSelector(projectAtmosphericLogsSelector, areEqual);
  const externalAtmosphericLogsCreated = useSelector(externalAtmosphericLogsCreatedSelector, areEqual);

  // local variables
  const [fetching, setFetching] = useState(false);

  const handleViewPicture = useCallback((log: any) => {
    setSelectedAtmosphericLog(log);
    setSelectedAtmosphericLogType(atmosphericLogType.External);
    setIsViewAtmosphericLogModalOpen(true);
  }, []);

  const handleAddLog = useCallback(() => {
    setIsAddExternalAtmosphericLogModalOpen(true);
  }, []);

  const getReports = useCallback(() => {
    const { id: projectId } = project;

    dispatch(getProjectAtmosphericLogsWithPhoto(projectId, setFetching));
  }, [project]);

  useEffect(() => {
    if (externalAtmosphericLogsCreated) {
      dispatch(setExternalAtmosphericLogCreated(false));
      getReports();
    }
  }, [externalAtmosphericLogsCreated]);

  useEffect(() => {
    if (project?.id) {
      getReports();
    }
  }, [project]);

  return (
    <>
      <SpinnerBlock fetching={fetching} />
      {!fetching && (
        <ExternalAtmosphericTable
          headers={externalAtmosphericTableHeaders}
          logs={atmosphericLogs}
          handleViewPicture={handleViewPicture}
          handleAddLog={handleAddLog}
        />
      )}
    </>
  );
}

const ExternalAtmosphericTableContainerMemo = memo(ExternalAtmosphericTableContainer, areEqual);

export { ExternalAtmosphericTableContainerMemo as ExternalAtmosphericTable };
