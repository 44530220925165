import { handleApiRequest } from 'Utils/handleApiRequest';

import { ExternalAtmosphericLog } from './types';

export const SET_PROJECT_ATMOSPHERIC_LOGS = 'SET_PROJECT_ATMOSPHERIC_LOGS';
export const SET_PROJECT_EQUIPMENT_LIST = 'SET_PROJECT_EQUIPMENT_LIST';
export const EXTERNAL_ATMOSPHERIC_LOG_CREATED = 'EXTERNAL_ATMOSPHERIC_LOG_CREATED';
export const CREATE_EXTERNAL_ATMOSPHERIC_LOG_ERRORS = 'CREATE_EXTERNAL_ATMOSPHERIC_LOG_ERRORS';

interface ActionTypes {
  SET_PROJECT_ATMOSPHERIC_LOGS: ExternalAtmosphericLog[];
  SET_PROJECT_EQUIPMENT_LIST: any;
  EXTERNAL_ATMOSPHERIC_LOG_CREATED: boolean;
  CREATE_EXTERNAL_ATMOSPHERIC_LOG_ERRORS: any;
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type RocketDryActionTypes = MessageAction;

/*
 * NON-API THUNKS
 * */

export const setProjectAtmosphericLogs = (logs: any[]) => async (dispatch: any) => {
  dispatch({
    type: SET_PROJECT_ATMOSPHERIC_LOGS,
    payload: logs,
  });
};

export const setExternalAtmosphericLogCreated = (payload: boolean) => async (dispatch: any) => {
  dispatch({
    type: EXTERNAL_ATMOSPHERIC_LOG_CREATED,
    payload,
  });
};

export const setProjectEquipmentList = (list: any[]) => async (dispatch: any) => {
  dispatch({
    type: SET_PROJECT_EQUIPMENT_LIST,
    payload: list,
  });
};

/*
 * API THUNKS
 * */

/* eslint-disable */

export const listLocationsForRocketDry =
  (propertyId: number, pageNumber = 1) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.get(`/properties/${propertyId}/locations`, {
        params: {
          include: 'locationType,sourceRoom,asbestosStatus',
          sort: '-floor_number,location_type,-name',
          page: pageNumber,
        },
      })
    );
  };

export const listLocationRoomsForRocketDry =
  (locationId: number, pageNumber = 1) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.get(`/locations/${locationId}/rooms`, {
        params: {
          include: 'roomType,level',
          page: pageNumber,
        },
      })
    );
  };

/*
 * Moisture atmospheric APIs
 */

export const getProjectAtmosphericLogs =
  (projectId: number, setFetching: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setFetching(true);
    const response = await handleApiRequest(dispatch, utils.Api.get(`projects/${projectId}/atmospheric-logs`));

    if (response?.data) {
      const { data: unprocessedExternalLogs } = response;
      const externalAtmosphericLogs: ExternalAtmosphericLog[] = unprocessedExternalLogs.map((log) => {
        return {
          id: log.id,
          createdAt: log.created_at,
          relativeHumidity: log.relative_humidity,
          temperature: log.temperature,
          pressure: log.pressure,
          windSpeed: log.wind_speed,
        };
      });
      dispatch(setProjectAtmosphericLogs(externalAtmosphericLogs));
      setFetching(false);
    } else {
      setFetching(false);
    }
  };

export const getProjectAtmosphericLogsWithPhoto =
  (projectId: number, setFetching: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setFetching(true);

    let more = true;
    let unprocessedExternalLogs = [];
    let page = 1;

    while (more) {
      const response = await handleApiRequest(
        dispatch,
        utils.Api.get(`projects/${projectId}/atmospheric-logs`, {
          params: {
            include: 'photo,creator',
            page,
          },
        })
      );

      if (response?.data && response?.meta) {
        unprocessedExternalLogs = unprocessedExternalLogs.concat(response.data);

        const {
          meta: { current_page: currentPage, last_page: lastPage },
        } = response;

        more = currentPage < lastPage;
        page += 1;
      } else {
        break;
      }
    }

    const externalAtmosphericLogs: ExternalAtmosphericLog[] = unprocessedExternalLogs.map((log) => {
      const externalLog: ExternalAtmosphericLog = {
        id: log.id,
        createdAt: log.created_at,
        relativeHumidity: log.relative_humidity,
        temperature: log.temperature,
        pressure: log.pressure,
        windSpeed: log.wind_speed,
        photo: log.photo,
        creator: { id: log.creator.id, name: log.creator.full_name },
      };
      if (log.updated_at !== log.created_at) {
        externalLog.updatedAt = log.updated_at;
      }
      return externalLog;
    });

    dispatch(setProjectAtmosphericLogs(externalAtmosphericLogs));
    setFetching(false);
  };

export const getRoomAtmosphericLogs =
  (roomId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.get(`rooms/${roomId}/atmospheric-logs`, {
        params: {
          sort: '-room_area,-created_at',
        },
      })
    );
  };

export const getRoomAtmosphericLogsWithPhoto =
  (roomId: number, pageNumber?: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.get(`rooms/${roomId}/atmospheric-logs`, {
        params: {
          page: pageNumber ?? 1,
          sort: '-room_area,-created_at',
          include: 'photo, creator',
          per_page: 60,
        },
      })
    );
  };

export const addRoomExternalAtmosphericLogs =
  (
    projectId: number,
    relativeHumidity: string,
    temperature: string,
    pressure: string,
    windspeed: string,
    onFinish?: any
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`projects/${projectId}/atmospheric-logs`, {
        relative_humidity: relativeHumidity,
        temperature,
        pressure,
        wind_speed: windspeed,
      }),
      CREATE_EXTERNAL_ATMOSPHERIC_LOG_ERRORS
    );

    if (response?.data) {
      dispatch(setExternalAtmosphericLogCreated(true));
      if (onFinish) {
        onFinish();
      }
    }
  };

export const addRoomAtmosphericLogs =
  (
    roomId: number,
    relativeHumidity: string,
    temperature: string,
    dewPoint: string,
    gpp: string,
    roomArea?: string,
    inletId?: number
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const logData = {
      relative_humidity: relativeHumidity,
      temperature,
      dew_point: dewPoint,
      gpp,
    };

    if (roomArea) {
      logData['room_area'] = roomArea;
    }
    if (inletId) {
      logData['inlet_id'] = inletId;
    }

    return await handleApiRequest(dispatch, utils.Api.post(`rooms/${roomId}/atmospheric-logs`, logData));
  };

export const getMaterialWithDryingLogs =
  (roomId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.get(`rooms/${roomId}/damage-materials/logs`, {
        params: {
          include: 'photo, creator',
        },
      })
    );
  };

export const getDryingLogsForMaterial =
  (roomId: number, materialID: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.get(`rooms/${roomId}/damage-materials/${materialID}/logs`, { params: { include: 'photo, creator' } })
    );
  };

export const setDryingGoal =
  (roomId: number, materialId: number, dryingGoal: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`rooms/${roomId}/damage-materials/${materialId}`, {
        drying_goal: dryingGoal,
      })
    );
    if (response?.data) {
      const { data } = response;
      // handle response
    }
  };

export const createDryingLog =
  (roomId: number, materialId: number, reading: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`rooms/${roomId}/damage-materials/${materialId}}/logs`, {
        reading: reading,
      })
    );
    if (response?.data) {
      const { data } = response;
      // handle response
    }
  };

/*
 * Equipment log APIs
 */

export const createCustomEquipmentType =
  (projectId: number, name: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`projects/${projectId}/equipment`, {
        name,
      })
    );
    if (response?.data) {
      const { data } = response;
      // handle response
    }
  };

export const getProjectEquipmentList =
  (projectId: number, setFetching: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setFetching(true);
    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`projects/${projectId}/equipment`, {
        params: {
          include: 'count',
        },
      })
    );

    if (response?.data) {
      const { data } = response;
      dispatch(setProjectEquipmentList(data));
      setFetching(false);
    } else {
      setFetching(false);
    }
  };

export const addEquipmentToRoom =
  (roomId: number, equipmentIds: number[]) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.post(`rooms/${roomId}/equipment`, {
        equipment_ids: equipmentIds,
      })
    );
  };

export const getRoomEquipmentList =
  (roomId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.get(`rooms/${roomId}/equipment`));
  };

export const updateEquipmentInRoom =
  (equipmentRoomId: number, quantity: number, dateIn?: string, dateOut?: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.put(`equipment-rooms/${equipmentRoomId}`, {
        quantity,
        date_in: dateIn ?? null,
        date_out: dateOut ?? null,
      })
    );
  };

export const deleteEquipmentInRoom =
  (equipmentRoomId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.delete(`equipment-rooms/${equipmentRoomId}`));
  };
