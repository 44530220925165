import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { ScopeSheet } from 'Containers/RocketScope/types';
import { PurpleButton } from 'Components/Button';
import classes from './sheetNameMenu.module.css';

interface Props {
  scopeSheets: ScopeSheet[];
  onSheetSelectClick: (id: number) => void;
  outlineNotSelected: boolean;
}

const SheetNameMenu = ({ scopeSheets, onSheetSelectClick, outlineNotSelected }: Props) => {


  return (
    <div className={`d-flex align-items-center ${classes.sheetNameList}`}>
      {scopeSheets.map((item) => (
        <PurpleButton
          className={classes.sheetNameButton}
          outlined={outlineNotSelected && !item.selected}
          key={item.id}
          onClick={() => onSheetSelectClick(item.id)}
        >
          {item.name}
        </PurpleButton>
      ))}
    </div>
  );
};

const SheetNameMenuMemo = memo(SheetNameMenu, areEqual);

export { SheetNameMenuMemo as SheetNameMenu };
