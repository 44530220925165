import React, { memo, useCallback } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { ReportLocation } from 'Components/ReportsAndDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedReportLocations, setSelectedReportRooms } from 'Containers/ReportsAndDocuments/Reports/actions';
import {
  selectedReportLocationsSelector,
  selectedReportRoomsSelector,
} from 'Containers/ReportsAndDocuments/Reports/selectors';
import { Room } from 'Types/locations';

interface Props {
  location: {
    id: number;
    name: string;
    rooms: Room[];
  };
}

const ReportLocationContainer = ({ location }: Props) => {
  const dispatch = useDispatch();

  const { id, name, rooms } = location;

  const selectedReportLocations = useSelector(selectedReportLocationsSelector, areEqual);
  const selectedReportRooms = useSelector(selectedReportRoomsSelector, areEqual);

  const onLocationClick = useCallback(() => {
    if (selectedReportLocations.includes(id)) {
      // Unselect location and all rooms
      rooms.forEach((room) => {
        if (selectedReportRooms.includes(room.id)) {
          dispatch(setSelectedReportRooms(room.id));
        }
      });
    } else {
      // Select location and all rooms
      rooms.forEach((room) => {
        if (!selectedReportRooms.includes(room.id)) {
          dispatch(setSelectedReportRooms(room.id));
        }
      });
    }
    dispatch(setSelectedReportLocations(id));
  }, [id, rooms, selectedReportLocations]);

  const onRoomClick = useCallback(
    (roomId: number) => {
      const currentLocationRoomIds = rooms.map((room) => room.id);

      if (selectedReportRooms.includes(roomId)) {
        // Unselect room
        dispatch(setSelectedReportRooms(roomId));
        // Check if it is the last selected room for the current location
        const remainingSelectedRooms = selectedReportRooms.filter(
          (room) => currentLocationRoomIds.includes(room) && room !== roomId
        );
        if (remainingSelectedRooms.length === 0 && selectedReportLocations.includes(id)) {
          dispatch(setSelectedReportLocations(id));
        }
      } else {
        // Select room
        dispatch(setSelectedReportRooms(roomId));
      }
    },
    [id, rooms, selectedReportRooms]
  );

  return (
    <ReportLocation
      name={name}
      selectedLocations={selectedReportLocations.includes(id)}
      selectedRooms={selectedReportRooms}
      rooms={rooms}
      onLocationClick={onLocationClick}
      onRoomClick={onRoomClick}
    />
  );
};

const ReportLocationContainerMemo = memo(ReportLocationContainer, areEqual);

export { ReportLocationContainerMemo as ReportLocationContainer };
