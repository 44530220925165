import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { RocketDryRoomHeader, NoRecordingsPlaceholder } from 'Components/RocketDry';
import { InternalAtmosphericTable, MoistureLogs } from 'Containers/RocketDry';
import { SpinnerBlock } from 'Components/SpinnerBlock';
import { PurpleButton } from 'Components/Button';

import { getRoomAtmosphericLogsWithPhoto } from 'Containers/RocketDry/actions';

import { useRocketDryFunctions } from 'Context/RocketDry';

import { internalAtmosphericTableHeaders } from 'Utils/table';
import { generateUUID } from 'Utils/helpers';

import classes from './moistureAtmosphericRoom.module.css';

interface Props {
  room: any;
}

interface LogGroupByArea {
  areaName: string; // displayed to user
  areaNameInternal: string; // used internally for processing
  logs: any[];
}

const defaultRoomAreaPrefix: string = 'RocketPlan-DefaultRoomArea';

const compareLogAreas = (group1: LogGroupByArea, group2: LogGroupByArea) => {
  if (group1.logs[0].created_at < group2.logs[0].created_at) {
    return -1;
  }

  if (group1.logs[0].created_at > group2.logs[0].created_at) {
    return 1;
  }

  return 0;
};

const GroupRoomAtmosphericLogsByArea = (unprocessedLogs): LogGroupByArea[] =>
  Object.values(
    unprocessedLogs.reduce((logsInfoTemp: LogGroupByArea[], logEntry) => {
      // get room area
      const roomArea: string = logEntry.room_area ?? 'null';

      // set the room area array if it doesn't exist yet
      if (!logsInfoTemp[roomArea]) {
        // create temp alias if needed
        logsInfoTemp[roomArea] = {
          areaName: roomArea,
          logs: [],
        };
      }

      // assign each log to its respective group
      logsInfoTemp[roomArea].logs.push(logEntry);

      return logsInfoTemp;
    }, [])
  );

function MoistureAtmosphericRoomContainer({ room }: Props) {
  const dispatch = useDispatch();

  const mounted = useRef(true);

  const [logs, setLogs] = useState([]);
  const [fetching, setFetching] = useState(false);

  const {
    internalAtmosphericLogCreated,
    setInternalAtmosphericLogCreated,
    selectedRoomId,
    setIsAddInternalAtmosphericLogModalOpen,
    setSelectedRoomId,
    setSelectedRoomArea,
  }: any = useRocketDryFunctions();

  // room data
  const {
    id: roomId,
    room_type: { name: roomType },
  } = room;

  const getInternalAtmosphericLogs = useCallback(async () => {
    setFetching(true);

    let more = true;
    let moistureLogs = [];
    let page = 1;
    while (more) {
      // ignore eslint's no-await-in-loop error - we need to wait for one page to be fetched before getting the next page
      // eslint-disable-next-line
      const currentData: any = await dispatch(getRoomAtmosphericLogsWithPhoto(roomId, page));

      if (currentData?.data && currentData?.meta) {
        moistureLogs = moistureLogs.concat(currentData?.data);

        const {
          meta: { current_page: currentPage, last_page: lastPage },
        } = currentData;

        more = currentPage < lastPage;
        page += 1;
      } else {
        break;
      }
    }

    if (mounted) {
      if (moistureLogs) {
        let groupedLogs: LogGroupByArea[] = [];
        if (moistureLogs.length > 0) {
          groupedLogs = GroupRoomAtmosphericLogsByArea(moistureLogs).sort(compareLogAreas);
        }

        let count = 1;
        groupedLogs.forEach((logGroup: LogGroupByArea) => {
          logGroup.areaNameInternal = logGroup.areaName;
          if (logGroup.areaName.includes(defaultRoomAreaPrefix)) {
            logGroup.areaName = `#${count.toString()}`;
            count += 1;
          }
        });

        setLogs(groupedLogs);
      } else {
        setLogs([]);
      }

      setFetching(false);
    }
  }, [roomId]);

  // initial fetch
  useEffect(() => {
    mounted.current = true;
    (async function fetchData() {
      await getInternalAtmosphericLogs();
    })();

    return () => {
      if (mounted) {
        mounted.current = false;
      }
    };
  }, []);

  // fetch on update
  useEffect(() => {
    if (internalAtmosphericLogCreated && selectedRoomId === roomId) {
      (async function fetchData() {
        await getInternalAtmosphericLogs();
        setInternalAtmosphericLogCreated(false);
      })();
    }
  }, [internalAtmosphericLogCreated, roomId, selectedRoomId]);

  const handleAddLog = useCallback(() => {
    setIsAddInternalAtmosphericLogModalOpen(true);
    setSelectedRoomId(roomId);
    const uuid = generateUUID();
    const autoGeneratedRoomArea = defaultRoomAreaPrefix + uuid;
    setSelectedRoomArea(autoGeneratedRoomArea);
  }, [roomId]);

  return (
    <div>
      <RocketDryRoomHeader icon={roomType} title={roomType} />

      <h3 className={classes.sectionHeader}>Internal Atmospheric</h3>
      <SpinnerBlock fetching={fetching} />
      {!fetching && logs?.length > 0 ? (
        logs.map((roomArea: any) => (
          <InternalAtmosphericTable
            key={roomArea.areaName}
            roomAreaName={roomArea.areaName}
            roomAreaNameInternal={roomArea.areaNameInternal}
            roomId={roomId}
            headers={internalAtmosphericTableHeaders}
            logs={roomArea.logs}
          />
        ))
      ) : (
        <NoRecordingsPlaceholder />
      )}
      <br />
      <PurpleButton onClick={handleAddLog}>Start new atmospheric log</PurpleButton>
      <h3 className={classes.sectionHeader}>Moisture Logs</h3>
      <MoistureLogs roomId={roomId} />
    </div>
  );
}

const MoistureAtmosphericRoomContainerMemo = memo(MoistureAtmosphericRoomContainer, areEqual);

export { MoistureAtmosphericRoomContainerMemo as MoistureAtmosphericRoom };
