import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-min.module.css';

import { areEqual } from 'Utils/equalityChecks';
import formClasses from 'Themes/form/form.module.css';
import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { ValidateBackGround } from 'Components/Validation';

import { Icon } from 'Components/Icons';
import { ReportLocations, ReportScopeOfWork } from 'Containers/ReportsAndDocuments';
import { PurpleButton, RadioButton } from 'Components/Button';
import { CompanyInfo, ReportSettings, PropertyData, LossData, ClaimsData } from 'Types/reports';
import classes from './photoReportForm.module.css';

interface Props {
  title: string;
  companyInfo: CompanyInfo;
  reportSettings: ReportSettings;
  propertyData: PropertyData;
  lossData: LossData;
  claims: ClaimsData;
  formErrors: { title: string[] };
  fetching: boolean;
  onChangeTitle: (e: any) => void;
  onCompanyClick: (e: any) => void;
  onSettingsChange: {
    onSelectFormat: (e: any) => void;
    onUnitTypeClick: (e: any) => void;
    onPhotosTypeClick: (e: any) => void;
    onNotesTypeClick: (e: any) => void;
    onPhotoNotesTypeClick: (e: any) => void;
    onDamagedMaterialsClick: (e: any) => void;
    onScopeOfWorkClick: (e: any) => void;
    onScopeOfWorkCostClick: (e: any) => void;
    onSketchVirtualTourClick: (e: any) => void;
    onDateRangeClick: (e: any) => void;
    onDateRangeChange: (dates: [Date, Date]) => void;
    onRocketDryClick: (field: string) => void;
    onESignatureAndFormsClick: (e: any) => void;
    onUploadedDocumentsClick: (e: any) => void;
  };
  onPropertyDataChange: (field: string) => void;
  onLossDataChange: (field: string) => void;
  onClaimsChange: (field: string) => void;
  onFormSubmit: (e: any) => void;
  projectStartDate: Date;
  onToggleAllPropertyData: () => void;
  onToggleAllLossData: () => void;
  onToggleAllClaims: () => void;
}

const PhotoReportForm = ({
  title,
  companyInfo,
  reportSettings,
  propertyData,
  lossData,
  claims,
  formErrors,
  fetching,
  onChangeTitle,
  onCompanyClick,
  onSettingsChange,
  onPropertyDataChange,
  onLossDataChange,
  onClaimsChange,
  onFormSubmit,
  projectStartDate,
  onToggleAllPropertyData,
  onToggleAllLossData,
  onToggleAllClaims,
}: Props) => (
  <div className={classes.generateReportsForm}>
    <h4 className={classes.title}>Create a Report</h4>
    <form className={classes.form} onSubmit={onFormSubmit}>
      <ValidateBackGround isValid={!formErrors?.title.length} className={formClasses.inputContainer}>
        <Label ariaLabel="Report Title" className={formClasses.label} htmlFor="title">
          Report Title
        </Label>
        <TextBox
          value={title}
          name="title"
          type="text"
          className={`mb-0 pb-0 ${formClasses.validateField} ${
            formErrors?.title.length ? formClasses.invalidField : formClasses.validField
          } ${formErrors?.title.length ? 'is-invalid' : ''}`}
          required
          placeholder="Report Name Here"
          ariaLabel="Please enter report title"
          onChange={onChangeTitle}
          autoComplete="off"
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.title.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.title?.[0]}
        </div>
      </ValidateBackGround>
      <br />
      {companyInfo.showSecondaryOption && (
        <>
          <span className={`d-block mb-2 ${formClasses.label} mt-2`}>Use Company</span>
          <div className="d-flex mb-4">
            <RadioButton
              onClick={onCompanyClick}
              selected={!companyInfo.useSecondary}
              value="no"
              className={classes.buttonSpace}
            >
              {companyInfo.logo && <img className={classes.companyLogo} alt="avatar" src={companyInfo.logo} />}
              {companyInfo.name}
            </RadioButton>
            <RadioButton onClick={onCompanyClick} selected={companyInfo.useSecondary} value="yes">
              {companyInfo.secondaryLogo && (
                <img className={classes.companyLogo} alt="avatar" src={companyInfo.secondaryLogo} />
              )}
              {companyInfo.secondaryName}
            </RadioButton>
          </div>
        </>
      )}

      <div className={classes.projectLossInfo}>
        <h5 className={formClasses.sectionTitle}>Project Info</h5>

        <div className={classes.subsection}>
          <div className={classes.subsectionHeader}>
            <span className={formClasses.label}>Property Data</span>
            <button type="button" onClick={onToggleAllPropertyData} className={classes.toggleButton}>
              {Object.values(propertyData).every((value) => value) ? 'Unselect All' : 'Select All'}
            </button>
          </div>
          <div className={classes.checkboxGroup}>
            <label>
              <input
                type="checkbox"
                checked={propertyData.projectNumber}
                onChange={() => onPropertyDataChange('projectNumber')}
              />{' '}
              Project Number
            </label>
            <label>
              <input
                type="checkbox"
                checked={propertyData.projectAlias}
                onChange={() => onPropertyDataChange('projectAlias')}
              />{' '}
              Project Alias
            </label>
            <label>
              <input
                type="checkbox"
                checked={propertyData.projectType}
                onChange={() => onPropertyDataChange('projectType')}
              />{' '}
              Project Type
            </label>
            <label>
              <input
                type="checkbox"
                checked={propertyData.referredBy}
                onChange={() => onPropertyDataChange('referredBy')}
              />{' '}
              Referred By
            </label>
            <label>
              <input type="checkbox" checked={propertyData.phone} onChange={() => onPropertyDataChange('phone')} />{' '}
              Phone
            </label>
            <label>
              <input
                type="checkbox"
                checked={propertyData.platinumAgent}
                onChange={() => onPropertyDataChange('platinumAgent')}
              />{' '}
              Platinum Agent
            </label>
            <label>
              <input
                type="checkbox"
                checked={propertyData.propertyClassification}
                onChange={() => onPropertyDataChange('propertyClassification')}
              />{' '}
              Property Classification
            </label>
            <label>
              <input
                type="checkbox"
                checked={propertyData.asbestosStatus}
                onChange={() => onPropertyDataChange('asbestosStatus')}
              />{' '}
              Asbestos Status
            </label>
            <label>
              <input
                type="checkbox"
                checked={propertyData.yearBuilt}
                onChange={() => onPropertyDataChange('yearBuilt')}
              />{' '}
              Year Built
            </label>
            <label>
              <input
                type="checkbox"
                checked={propertyData.buildingName}
                onChange={() => onPropertyDataChange('buildingName')}
              />{' '}
              Building Name
            </label>
          </div>
        </div>

        <div className={classes.subsection}>
          <div className={classes.subsectionHeader}>
            <span className={formClasses.label}>Loss Data</span>
            <button type="button" onClick={onToggleAllLossData} className={classes.toggleButton}>
              {Object.values(lossData).every((value) => value) ? 'Unselect All' : 'Select All'}
            </button>
          </div>
          <div className={classes.checkboxGroup}>
            <label>
              <input
                type="checkbox"
                checked={lossData.typeOfDamage}
                onChange={() => onLossDataChange('typeOfDamage')}
              />{' '}
              Type Of Damage
            </label>
            <label>
              <input
                type="checkbox"
                checked={lossData.causeOfDamage}
                onChange={() => onLossDataChange('causeOfDamage')}
              />{' '}
              Cause Of Damage
            </label>
            <label>
              <input type="checkbox" checked={lossData.category} onChange={() => onLossDataChange('category')} />{' '}
              Category
            </label>
            <label>
              <input type="checkbox" checked={lossData.class} onChange={() => onLossDataChange('class')} /> Class
            </label>
            <label>
              <input type="checkbox" checked={lossData.dateOfLoss} onChange={() => onLossDataChange('dateOfLoss')} />{' '}
              Date of Loss
            </label>
            <label>
              <input
                type="checkbox"
                checked={lossData.callReceived}
                onChange={() => onLossDataChange('callReceived')}
              />{' '}
              Call Received
            </label>
            <label>
              <input
                type="checkbox"
                checked={lossData.dispatchTime}
                onChange={() => onLossDataChange('dispatchTime')}
              />{' '}
              Dispatch Time
            </label>
            <label>
              <input type="checkbox" checked={lossData.arrivalTime} onChange={() => onLossDataChange('arrivalTime')} />{' '}
              Arrival Time
            </label>
          </div>
        </div>

        <div className={classes.subsection}>
          <div className={classes.subsectionHeader}>
            <span className={formClasses.label}>Claims</span>
            <button type="button" onClick={onToggleAllClaims} className={classes.toggleButton}>
              {Object.values(claims).every((value) => value) ? 'Unselect All' : 'Select All'}
            </button>
          </div>
          <div className={classes.checkboxGroup}>
            <label>
              <input type="checkbox" checked={claims.policyNumber} onChange={() => onClaimsChange('policyNumber')} />{' '}
              Policy Number
            </label>
            <label>
              <input
                type="checkbox"
                checked={claims.representative}
                onChange={() => onClaimsChange('representative')}
              />{' '}
              Representative
            </label>
            <label>
              <input type="checkbox" checked={claims.contactStatus} onChange={() => onClaimsChange('contactStatus')} />{' '}
              Contact Status
            </label>
            <label>
              <input type="checkbox" checked={claims.phone} onChange={() => onClaimsChange('phone')} /> Phone
            </label>
            <label>
              <input type="checkbox" checked={claims.email} onChange={() => onClaimsChange('email')} /> Email
            </label>
            <label>
              <input type="checkbox" checked={claims.provider} onChange={() => onClaimsChange('provider')} /> Provider
            </label>
            <label>
              <input type="checkbox" checked={claims.deductible} onChange={() => onClaimsChange('deductible')} />{' '}
              Deductible
            </label>
            <label>
              <input type="checkbox" checked={claims.claimNumber} onChange={() => onClaimsChange('claimNumber')} />{' '}
              Claim #
            </label>
            <label>
              <input type="checkbox" checked={claims.adjusterName} onChange={() => onClaimsChange('adjusterName')} />{' '}
              Adjuster Name
            </label>
            <label>
              <input type="checkbox" checked={claims.adjusterPhone} onChange={() => onClaimsChange('adjusterPhone')} />{' '}
              Adjuster Phone
            </label>
            <label>
              <input type="checkbox" checked={claims.adjusterEmail} onChange={() => onClaimsChange('adjusterEmail')} />{' '}
              Adjuster Email
            </label>
          </div>
        </div>
      </div>

      <div className={classes.divider} />

      <div className="d-flex">
        <div className={classes.reportsFormat}>
          <span className={formClasses.label}>Report Format</span>
          <div className="d-flex mt-3 justify-content-between">
            <div>
              <p className={classes.reportsFormatTitle}>Compact</p>
              <span
                id="compact"
                role="button"
                onClick={onSettingsChange.onSelectFormat}
                onKeyUp={onSettingsChange.onSelectFormat}
                tabIndex={0}
                aria-label="compact-size"
              >
                <Icon
                  type="reportscompact"
                  className={reportSettings.format === 'compact' ? classes.formatSelected : ''}
                />
              </span>
            </div>
            <div>
              <p className={classes.reportsFormatTitle}>Expanded</p>
              <span
                id="expanded"
                role="button"
                onClick={onSettingsChange.onSelectFormat}
                onKeyUp={onSettingsChange.onSelectFormat}
                tabIndex={0}
                aria-label="expanded-size"
              >
                <Icon
                  type="reportsexpanded"
                  className={reportSettings.format === 'expanded' ? classes.formatSelected : ''}
                />
              </span>
            </div>
            {/*  <div> */}
            {/*    <p className={classes.reportsFormatTitle}>Large</p> */}
            {/*    <span */}
            {/*      id="large" */}
            {/*      role="button" */}
            {/*      onClick={onSettingsChange.onSelectFormat} */}
            {/*      onKeyUp={onSettingsChange.onSelectFormat} */}
            {/*      tabIndex={0} */}
            {/*      aria-label="large-size" */}
            {/*    > */}
            {/*      <Icon type="reportslarge" className={reportSettings.format === 'large' ? classes.formatSelected : ''} /> */}
            {/*    </span> */}
            {/*  </div> */}
          </div>
        </div>
        <div className={classes.divider} />
      </div>
      <span className={`d-block mb-2 ${formClasses.label} mt-2`}>Units</span>
      <div className="d-flex">
        <RadioButton
          onClick={onSettingsChange.onUnitTypeClick}
          selected={reportSettings.unitType === 'full_project'}
          value="full_project"
          className={classes.buttonSpace}
        >
          Full Project
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onUnitTypeClick}
          selected={reportSettings.unitType === 'locations'}
          value="locations"
        >
          Specific Locations
        </RadioButton>
      </div>

      {reportSettings.unitType === 'locations' && (
        <div className="py-3">
          <ReportLocations />
        </div>
      )}

      <div className={classes.divider} />

      <span className={`d-block mb-2 ${formClasses.label}`}>Date</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onDateRangeClick}
          selected={reportSettings.dateRange === 'all'}
          value="all"
          className={classes.buttonSpace}
        >
          All Time
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onDateRangeClick}
          selected={reportSettings.dateRange === 'custom'}
          value="custom"
          className={classes.buttonSpace}
        >
          Custom Date
        </RadioButton>
      </div>

      {reportSettings.dateRange === 'custom' && (
        <div className={classes.datePickerContainer}>
          <DatePicker
            selectsRange
            isClearable
            startDate={reportSettings.startDate}
            endDate={reportSettings.endDate}
            onChange={(dates: [Date, Date]) => onSettingsChange.onDateRangeChange(dates as [Date, Date])}
            minDate={projectStartDate}
            maxDate={new Date()}
            className={classes.datePicker}
            monthsShown={2}
          />
        </div>
      )}

      <div className={classes.divider} />

      <h5 className={formClasses.sectionTitle}>RocketScan</h5>

      <span className={`d-block mb-2 ${formClasses.label}`}>Photos</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onPhotosTypeClick}
          selected={reportSettings.photosType === 'all'}
          value="all"
          className={classes.buttonSpace}
        >
          All Photos
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onPhotosTypeClick}
          selected={reportSettings.photosType === 'bookmark'}
          value="bookmark"
          className={classes.buttonSpace}
        >
          Bookmark Only
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onPhotosTypeClick}
          selected={reportSettings.photosType === 'exclude'}
          value="exclude"
          className={classes.buttonSpace}
        >
          Exclude Bookmark
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onPhotosTypeClick}
          selected={reportSettings.photosType === 'none'}
          value="none"
          className={classes.buttonSpace}
        >
          None
        </RadioButton>
      </div>
      <div className={classes.divider} />
      <span className={`d-block mb-2 ${formClasses.label}`}>Project, Unit and Room Notes</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onNotesTypeClick}
          selected={reportSettings.notesType === 'all'}
          value="all"
          className={classes.buttonSpace}
        >
          All Notes
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onNotesTypeClick}
          selected={reportSettings.notesType === 'bookmark'}
          value="bookmark"
          className={classes.buttonSpace}
        >
          Bookmark Only
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onNotesTypeClick}
          selected={reportSettings.notesType === 'exclude'}
          value="exclude"
          className={classes.buttonSpace}
        >
          Exclude Bookmark
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onNotesTypeClick}
          selected={reportSettings.notesType === 'none'}
          value="none"
        >
          None
        </RadioButton>
      </div>

      <span className={`d-block mb-2 ${formClasses.label}`}>Photo Notes</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onPhotoNotesTypeClick}
          selected={reportSettings.photoNotesType === 'all'}
          value="all"
          className={classes.buttonSpace}
        >
          All Photos
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onPhotoNotesTypeClick}
          selected={reportSettings.photoNotesType === 'bookmark'}
          value="bookmark"
          className={classes.buttonSpace}
        >
          Bookmark Only
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onPhotoNotesTypeClick}
          selected={reportSettings.photoNotesType === 'exclude'}
          value="exclude"
          className={classes.buttonSpace}
        >
          Exlude Bookmark
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onPhotoNotesTypeClick}
          selected={reportSettings.photoNotesType === 'none'}
          value="none"
          className={classes.buttonSpace}
        >
          None
        </RadioButton>
      </div>
      <div className={classes.divider} />
      <span className={`d-block mb-2 ${formClasses.label}`}>Scope of Work</span>

      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onScopeOfWorkClick}
          selected={reportSettings.scopeOfWork === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes (All Scopes)
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onScopeOfWorkClick}
          selected={reportSettings.scopeOfWork === 'specific'}
          value="specific"
        >
          Specific Scopes
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onScopeOfWorkClick}
          selected={reportSettings.scopeOfWork === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>

      {reportSettings.scopeOfWork === 'specific' && (
        <div className="pb-2">
          <ReportScopeOfWork />
        </div>
      )}

      <div className={classes.divider} />

      <span className={`d-block mb-2 ${formClasses.label}`}>Include $ on Scope of Work</span>

      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onScopeOfWorkCostClick}
          selected={reportSettings.scopeOfWorkCost === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onScopeOfWorkCostClick}
          selected={reportSettings.scopeOfWorkCost === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>
      {/* <div className={classes.divider} /> */}
      {/* <span className={`d-block mb-2 ${formClasses.label}`}>Damaged Materials</span> */}

      {/* <div className="d-flex mb-4"> */}
      {/*  <RadioButton */}
      {/*    onClick={onSettingsChange.onDamagedMaterialsClick} */}
      {/*    selected={reportSettings.damagedMaterials === 'yes'} */}
      {/*    value="yes" */}
      {/*    className={classes.buttonSpace} */}
      {/*  > */}
      {/*    Yes */}
      {/*  </RadioButton> */}
      {/*  <RadioButton */}
      {/*    onClick={onSettingsChange.onDamagedMaterialsClick} */}
      {/*    selected={reportSettings.damagedMaterials === 'no'} */}
      {/*    value="no" */}
      {/*  > */}
      {/*    No */}
      {/*  </RadioButton> */}
      {/* </div> */}
      <div className={classes.divider} />
      <span className={`d-block mb-2 ${formClasses.label}`}>Sketch & Virtual Tour</span>

      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onSketchVirtualTourClick}
          selected={reportSettings.sketchVirtualTour === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onSketchVirtualTourClick}
          selected={reportSettings.sketchVirtualTour === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>
      <div className={classes.divider} />

      <h5 className={formClasses.sectionTitle}>RocketDry</h5>

      <span className={`d-block mb-2 ${formClasses.label}`}>External Atmospheric Data</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('externalAtmosphericData')}
          selected={reportSettings.externalAtmosphericData === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('externalAtmosphericData')}
          selected={reportSettings.externalAtmosphericData === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>

      <span className={`d-block mb-2 ${formClasses.label}`}>Internal Atmospheric Data</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('internalAtmosphericData')}
          selected={reportSettings.internalAtmosphericData === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('internalAtmosphericData')}
          selected={reportSettings.internalAtmosphericData === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>

      <span className={`d-block mb-2 ${formClasses.label}`}>Moisture Logs</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('moistureLogs')}
          selected={reportSettings.moistureLogs === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('moistureLogs')}
          selected={reportSettings.moistureLogs === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>

      <span className={`d-block mb-2 ${formClasses.label}`}>Photos</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('rocketDryPhotos')}
          selected={reportSettings.rocketDryPhotos === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('rocketDryPhotos')}
          selected={reportSettings.rocketDryPhotos === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>

      <span className={`d-block mb-2 ${formClasses.label}`}>Equipment List</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('equipmentList')}
          selected={reportSettings.equipmentList === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={() => onSettingsChange.onRocketDryClick('equipmentList')}
          selected={reportSettings.equipmentList === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>

      <div className={classes.divider} />

      <h5 className={formClasses.sectionTitle}>E-Signature & Company Forms</h5>
      <span className={`d-block mb-2 ${formClasses.label}`}>Include E-Signature & Company Forms</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onESignatureAndFormsClick}
          selected={reportSettings.eSignatureAndForms === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onESignatureAndFormsClick}
          selected={reportSettings.eSignatureAndForms === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>

      <div className={classes.divider} />

      <h5 className={formClasses.sectionTitle}>Uploaded Documents</h5>
      <span className={`d-block mb-2 ${formClasses.label}`}>Include any Uploaded Documents</span>
      <div className="d-flex mb-4">
        <RadioButton
          onClick={onSettingsChange.onUploadedDocumentsClick}
          selected={reportSettings.uploadedDocuments === 'yes'}
          value="yes"
          className={classes.buttonSpace}
        >
          Yes
        </RadioButton>
        <RadioButton
          onClick={onSettingsChange.onUploadedDocumentsClick}
          selected={reportSettings.uploadedDocuments === 'no'}
          value="no"
        >
          No
        </RadioButton>
      </div>

      <div className={classes.divider} />

      <div className="w-100 d-flex justify-content-center">
        <PurpleButton type="submit" className={classes.submitButton} disabled={!title || fetching}>
          Generate Report
        </PurpleButton>
      </div>
    </form>
    <style>
      {`
      .${classes.datePickerContainer} .react-datepicker {
        display: flex;
      }
    `}
    </style>
  </div>
);

const PhotoReportFormMemo = memo(PhotoReportForm, areEqual);

export { PhotoReportFormMemo as PhotoReportForm };
