import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { SpinnerBlock } from 'Components/SpinnerBlock';

import { fetchScopeData } from 'Containers/RocketScope/actions';
import { scopeSheetsSelector } from 'Containers/RocketScope/selectors';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { selectedReportScopeOfWorkSelector } from 'Containers/ReportsAndDocuments/Reports/selectors';
import { Icon } from 'Components/Icons';
import { setSelectedScopeOfWork } from 'Containers/ReportsAndDocuments/Reports/actions';
import classes from './reportScopeOfWork.module.css';

const ReportScopeOfWorkContainer = () => {
  const dispatch = useDispatch();

  const scopeSheets = useSelector(scopeSheetsSelector, areEqual);
  const firstCompanyId = useSelector(firstCompanyIdSelector);
  const selectedScopeOfWork = useSelector(selectedReportScopeOfWorkSelector, areEqual);

  useEffect(() => {
    if (!scopeSheets) {
      dispatch(fetchScopeData(firstCompanyId));
    }
  }, [scopeSheets, firstCompanyId]);

  const onScopeClick = useCallback((scopeId: number) => {
    dispatch(setSelectedScopeOfWork(scopeId));
  }, []);

  return (
    <div>
      {!scopeSheets || scopeSheets.length === 0 ? (
        <SpinnerBlock fetching /> // Render the spinner when locations.length is 0
      ) : (
        scopeSheets.map((scopeSheet: any) => (
          <div
            key={scopeSheet.id}
            className={`d-flex align-items-center ${classes.optionBase}`}
            role="button"
            onClick={() => onScopeClick(scopeSheet.id)}
            onKeyUp={() => onScopeClick(scopeSheet.id)}
            tabIndex={0}
          >
            <Icon type={selectedScopeOfWork.includes(scopeSheet.id) ? 'checkbox' : 'square'} />
            <span className={classes.scopeSpan}>{scopeSheet.name}</span>
          </div>
        ))
      )}
    </div>
  );
};

const ReportScopeOfWorkContainerMemo = memo(ReportScopeOfWorkContainer, areEqual);

export { ReportScopeOfWorkContainerMemo as ReportScopeOfWorkContainer };
