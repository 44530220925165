import React, { memo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';

import { parseDateAsCalendarDate, formatAsISOCalendarDate } from 'Utils/helpers';

import { RoomEquipment } from 'Containers/RocketDry/types';
import { updateEquipmentInRoom } from 'Containers/RocketDry/actions';

import { EquipmentInfo } from 'Components/RocketDry/EquipmentLog/EquipmentInfo';

import { useRocketDryFunctions } from 'Context/RocketDry';

interface Props {
  equipment: RoomEquipment;
}

function EquipmentInfoContainer({ equipment }: Props) {
  const dispatch = useDispatch();

  const { setEquipmentUpdatedRoomId, setSelectedEquipment, setIsDeleteEquipmentModalOpen }: any =
    useRocketDryFunctions();

  const [showEditComponent, setShowEditComponent] = useState(false);
  const [dayIn, setDayIn] = useState(undefined);
  const [dayOut, setDayOut] = useState(undefined);
  const [quantity, setQuantity] = useState(undefined);

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setDayIn(start);
    setDayOut(end);
  };

  const onClickEdit = useCallback(() => {
    if (equipment.date_in) {
      setDayIn(parseDateAsCalendarDate(equipment.date_in));
    }
    if (equipment.date_out) {
      setDayOut(parseDateAsCalendarDate(equipment.date_out));
    }
    if (equipment.quantity) {
      setQuantity(equipment.quantity);
    }
    setShowEditComponent(true);
  }, [equipment]);

  const onDeleteClick = useCallback(() => {
    setSelectedEquipment(equipment);
    setIsDeleteEquipmentModalOpen(true);
  }, [equipment]);

  const onChangeQuantity = useCallback(({ target: { value } }) => {
    setQuantity(value);
  }, []);

  const closeEditComponent = useCallback(() => {
    setShowEditComponent(false);
    setQuantity(undefined);
    setDayIn(undefined);
    setDayOut(undefined);
  }, []);

  const onSaveButtonClick = useCallback(async () => {
    const dayInFormatted = dayIn ? formatAsISOCalendarDate(dayIn) : null;
    const dayOutFormatted = dayOut ? formatAsISOCalendarDate(dayOut) : null;

    // endpoint doesn't return anything on success
    await dispatch(updateEquipmentInRoom(equipment.id, quantity, dayInFormatted, dayOutFormatted));

    setEquipmentUpdatedRoomId(equipment.room_id);
    closeEditComponent();
  }, [equipment, quantity, dayIn, dayOut]);

  return (
    <EquipmentInfo
      equipment={equipment}
      showEditComponent={showEditComponent}
      quantity={quantity}
      dayIn={dayIn}
      dayOut={dayOut}
      onChangeQuantity={onChangeQuantity}
      onChangeDate={onChangeDate}
      onEditClick={onClickEdit}
      onDeleteClick={onDeleteClick}
      onSaveButtonClick={onSaveButtonClick}
      onCancelButtonClick={closeEditComponent}
    />
  );
}

const EquipmentInfoContainerMemo = memo(EquipmentInfoContainer, areEqual);

export { EquipmentInfoContainerMemo as EquipmentInfo };
