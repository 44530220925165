import React, { memo } from 'react';
import { Icon } from 'Components/Icons';

import { Modal } from 'Components/Modal';

import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { PurpleButton } from 'Components/Button';
import { ValidateBackGround } from 'Components/Validation';

import formClasses from 'Themes/form/form.module.css';
import classes from './addLogModal.module.css';

interface Props {
  // modal variables
  isOpen: boolean;
  formErrors: any;

  // atmospehric log variables
  relativeHumidity?: string;
  temperature?: string;
  pressure?: string;
  windSpeed?: string;

  // modal functions
  onSaveExternalButtonClick: (e: any) => void;
  closeModal: (e: any) => void;

  // atmospehric log functions
  onChangeRelativeHumidity: (e: any) => void;
  onChangeTemperature: (e: any) => void;
  onChangePressure: (e: any) => void;
  onChangeWindSpeed: (e: any) => void;
}

function AddExternalAtmosphericLogModal({
  isOpen,
  formErrors,
  relativeHumidity,
  temperature,
  pressure,
  windSpeed,
  onSaveExternalButtonClick,
  closeModal,
  onChangeRelativeHumidity,
  onChangeTemperature,
  onChangePressure,
  onChangeWindSpeed,
}: Props) {
  return (
    <Modal title="Add External Atmospheric Reading" isOpen={isOpen} classes={{ modalDialog: classes.modalOverride }}>
      <h5 className={classes.heading}>
        Add External Atmospheric Reading <Icon type="close" className={classes.closeModal} onClick={closeModal} />
      </h5>
      <form
        className={`requires-validation g-3 ${formClasses.formBase} ${classes.container}`}
        noValidate
        action="#"
        onSubmit={() => {}}
      >
        <div className={classes.formWrapper}>
          <div>
            <ValidateBackGround isValid={!formErrors?.relative_humidity?.length}>
              <Label ariaLabel="Relative Humidity" className={formClasses.label} htmlFor="relativeHumidity">
                Relative Humidity (%)
              </Label>
              <TextBox
                value={relativeHumidity}
                name="relativeHumidity"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.relative_humidity?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.relative_humidity?.length ? 'is-invalid' : ''}`}
                required
                placeholder=""
                ariaLabel="Please enter the relative humidity"
                onChange={onChangeRelativeHumidity}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>
                {formErrors.relative_humidity}
              </div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.temperature?.length}>
              <Label ariaLabel="Temperature" className={formClasses.label} htmlFor="temperature">
                Temperature (°F)
              </Label>
              <TextBox
                value={temperature}
                name="temperature"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.temperature?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.temperature?.length ? 'is-invalid' : ''}`}
                required
                placeholder=""
                ariaLabel="Please enter the temperature"
                onChange={onChangeTemperature}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>{formErrors.temperature}</div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.pressure?.length}>
              <Label ariaLabel="Pressure" className={formClasses.label} htmlFor="pressure">
                Pressure (kPA)
              </Label>
              <TextBox
                value={pressure}
                name="pressure"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.pressure?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.pressure?.length ? 'is-invalid' : ''}`}
                required
                placeholder=""
                ariaLabel="Please enter the pressure"
                onChange={onChangePressure}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>{formErrors.pressure}</div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.wind_speed?.length}>
              <Label ariaLabel="Wind Speed" className={formClasses.label} htmlFor="windSpeed">
                Wind Speed (mph)
              </Label>
              <TextBox
                value={windSpeed}
                name="windSpeed"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.wind_speed?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.wind_speed?.length ? 'is-invalid' : ''}`}
                required
                placeholder=""
                ariaLabel="Please enter the wind speed"
                onChange={onChangeWindSpeed}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>{formErrors.wind_speed}</div>
            </ValidateBackGround>
          </div>
        </div>
        <div className={`modal-footer ${classes.modalFooter}`}>
          <PurpleButton onClick={closeModal} className={classes.cancelButton}>
            Cancel
          </PurpleButton>
          <PurpleButton onClick={onSaveExternalButtonClick} className={classes.saveButton}>
            Save
          </PurpleButton>
        </div>
      </form>
    </Modal>
  );
}

const AddExternalAtmosphericLogModalMemo = memo(AddExternalAtmosphericLogModal);

export { AddExternalAtmosphericLogModalMemo as AddExternalAtmosphericLogModal };
