import { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { RoomEquipment } from 'Containers/RocketDry/types';

import { projectSelector, propertySelector } from 'Containers/RocketScan/selectors';

export const RocketDryContext = createContext({});

export const RocketDryFunctions = () => {
  const project = useSelector(projectSelector, areEqual);
  const property = useSelector(propertySelector, areEqual);

  // common state
  const [isEquipmentFolder, setIsEquipmentFolder] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(undefined);

  // atmospheric log
  const [isAddExternalAtmosphericLogModalOpen, setIsAddExternalAtmosphericLogModalOpen] = useState(false);
  const [isAddInternalAtmosphericLogModalOpen, setIsAddInternalAtmosphericLogModalOpen] = useState(false);
  const [isAddMoistureLogModalOpen, setIsAddMoistureLogModalOpen] = useState(false);
  const [isViewAtmosphericLogModalOpen, setIsViewAtmosphericLogModalOpen] = useState(false);
  const [selectedAtmosphericLog, setSelectedAtmosphericLog] = useState<RoomEquipment>(undefined);
  const [selectedAtmosphericLogType, setSelectedAtmosphericLogType] = useState(undefined);
  const [isViewMoistureLogModalOpen, setIsViewMoistureLogModalOpen] = useState(false);
  const [selectedRoomArea, setSelectedRoomArea] = useState(undefined);
  const [selectedMoistureLog, setSelectedMoistureLog] = useState<any>({});
  const [internalAtmosphericLogCreated, setInternalAtmosphericLogCreated] = useState(false);

  // equipment
  const [isAddEquipmentModalOpen, setIsAddEquipmentModalOpen] = useState(false);
  const [isDeleteEquipmentModalOpen, setIsDeleteEquipmentModalOpen] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<any>({});
  const [equipmentAdded, setEquipmentAdded] = useState(false);
  const [equipmentUpdatedRoomId, setEquipmentUpdatedRoomId] = useState(undefined);
  const [equipmentDeletedRoomId, setEquipmentDeletedRoomId] = useState(undefined);

  return {
    project,
    property,
    isEquipmentFolder,
    isAddExternalAtmosphericLogModalOpen,
    isAddInternalAtmosphericLogModalOpen,
    isViewAtmosphericLogModalOpen,
    selectedAtmosphericLog,
    selectedAtmosphericLogType,
    isAddMoistureLogModalOpen,
    isViewMoistureLogModalOpen,
    isAddEquipmentModalOpen,
    isDeleteEquipmentModalOpen,
    selectedMoistureLog,
    selectedRoomId,
    selectedRoomArea,
    selectedEquipment,
    internalAtmosphericLogCreated,
    equipmentAdded,
    equipmentUpdatedRoomId,
    equipmentDeletedRoomId,
    setIsEquipmentFolder,
    setIsAddExternalAtmosphericLogModalOpen,
    setIsAddInternalAtmosphericLogModalOpen,
    setIsAddMoistureLogModalOpen,
    setIsViewAtmosphericLogModalOpen,
    setSelectedAtmosphericLog,
    setSelectedAtmosphericLogType,
    setIsViewMoistureLogModalOpen,
    setIsAddEquipmentModalOpen,
    setIsDeleteEquipmentModalOpen,
    setSelectedMoistureLog,
    setSelectedRoomId,
    setSelectedRoomArea,
    setSelectedEquipment,
    setInternalAtmosphericLogCreated,
    setEquipmentAdded,
    setEquipmentUpdatedRoomId,
    setEquipmentDeletedRoomId,
  };
};

export const useRocketDryFunctions = () => useContext(RocketDryContext);
