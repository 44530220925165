import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Icon } from 'Components/Icons';
import { Room } from 'Types/locations';

import classes from './reportLocation.module.css';

interface Props {
  name: string;
  selectedLocations: boolean;
  selectedRooms: number[];
  rooms: Room[];
  onLocationClick: (e: any) => void;
  onRoomClick: (e: any) => void;
}

const ReportLocation = ({ name, selectedLocations, selectedRooms, rooms, onLocationClick, onRoomClick }: Props) => (
  <div className={classes.base}>
    <div
      className={`d-flex align-items-center ${classes.optionBase}`}
      role="button"
      onClick={onLocationClick}
      onKeyUp={onLocationClick}
      tabIndex={0}
    >
      <Icon type={selectedLocations ? 'checkbox' : 'square'} />
      <span className={classes.locationSpan}>{name}</span>
    </div>
    {selectedLocations && (
      <div className={classes.roomOptionsBase}>
        Rooms:&nbsp;&nbsp;
        {rooms.map((room: any) => (
          <div
            key={room.id}
            className={`d-flex align-items-center ${classes.optionBase}`}
            role="button"
            onClick={() => onRoomClick(room.id)}
            onKeyUp={() => onRoomClick(room.id)}
            tabIndex={0}
          >
            <Icon type={selectedRooms.includes(room.id) ? 'checkbox' : 'square'} />
            <span className={classes.roomSpan}>
              {`${room.room_type.name} ${room.type_occurrence > 1 ? room.type_occurrence : ''}`}
            </span>
          </div>
        ))}
      </div>
    )}
  </div>
);

const ReportLocationMemo = memo(ReportLocation, areEqual);

export { ReportLocationMemo as ReportLocation };
