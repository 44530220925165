import React, { memo } from 'react';
import { Icon } from 'Components/Icons';
import { formatDate } from 'Utils/helpers';

import { Modal } from 'Components/Modal';

import classes from './addLogModal.module.css';

function AddMoistureLogModal({ log, isOpen, setIsOpen }: any) {
  return (
    <Modal title="Internal Atmospheric Reading" isOpen={isOpen} classes={{ modalDialog: classes.modalOverride }}>
      <h5 className={classes.heading}>
        Internal Atmospheric Reading{' '}
        <Icon type="close" className={classes.closeModal} onClick={() => setIsOpen(false)} />
      </h5>
      <div className={classes.topWrapper}>
        <img src={log.photo && log.photo.sizes.raw} alt="Internal Atmospheric Reading" />
        <div className={classes.readingMetaData}>
          <div>
            Recorded By:
            {` ${log.creator?.full_name}` || ' - '}
          </div>
          <div>
            Recorded On:
            {` ${log.created_at ? formatDate(log.created_at, 'PP') : ' - '}`}
          </div>
          <div>
            Image Added:
            {` ${log.photo?.created_at ? formatDate(log.photo?.created_at, 'PP') : ' - '}`}
          </div>
          <div>
            Last Edited On:
            {` ${log.updated_at ? formatDate(log.updated_at, 'PP') : ' - '}`}
          </div>
        </div>
      </div>
      <div className={classes.bottomWrapper}>
        <div>
          <h5>Goal Average</h5>
          <span>{log.drying_goal || ' - '}</span>
        </div>
        <div>
          <h5>Average</h5>
          <span style={{ color: log.reading > log.drying_goal ? '#c50000' : '#4e8b43' }}>{log.reading || ' - '}</span>
        </div>
      </div>
    </Modal>
  );
}

const AddMoistureLogModalMemo = memo(AddMoistureLogModal);

export { AddMoistureLogModalMemo as AddMoistureLogModal };
