import React, { memo, ReactNode } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import classes from './selectEquipment.module.css';

interface Props {
  name: string;
  icon: ReactNode;
  selected: boolean;
  onClick: (e: any) => void;
}

const SelectEquipment = ({ name, icon, selected, onClick }: Props) => (
  <div
    role="button"
    className={`list-group-item-action ${classes.container} ${selected ? classes.selected : ''}`}
    onClick={onClick}
    onKeyUp={onClick}
    tabIndex={0}
  >
    <div className={classes.equipmentIcon}>{icon}</div>
    <div className={classes.equipmentText}>{name}</div>
  </div>
);

const SelectEquipmentMemo = memo(SelectEquipment, areEqual);

export { SelectEquipmentMemo as SelectEquipment };
