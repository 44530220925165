import React, { memo, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';
import { ScopeSheet, SelectedItem } from 'Containers/RocketScope/types';

import classes from './workScopeView.module.css';
import { SheetNameMenu } from 'Components/RocketScan';

interface Props {
  scopeSheets: ScopeSheet[];
}

const WorkScopeView = ({ scopeSheets }: Props) => {
  const { selectedItems } = useDamagedMaterialFunctions() as { selectedItems: SelectedItem[] };

  const [scopeCategories, setScopeCategories] = useState<{ [category: string]: SelectedItem[] }>({});
  const [scopeTotal, setScopeTotal] = useState(0.0);
  const [scopeSheetsAddAll, setScopeSheetsAddAll] = useState<ScopeSheet[]>([]);

  const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  useEffect(() => {
    setScopeSheetsAddAll([{ id: -1, name: 'All', data: [], selected: true }, ...scopeSheets.map(sheet => {
      sheet.selected = false;
      return sheet;
    })]);
  }, [scopeSheets]);

  useEffect(() => {
    const newScopeCategories: { [category: string]: SelectedItem[] } = {};
    let total = 0.0;
    const selectedScopeSheet = scopeSheetsAddAll.find((value) => value.selected);
    selectedItems.forEach((selectedItem) => {
      if (!selectedScopeSheet || selectedItem.sheetId === selectedScopeSheet.id || selectedScopeSheet.id === -1) {
        if (selectedItem.rate) {
          total += selectedItem.quantity * parseFloat(selectedItem.rate);
        }
        if (newScopeCategories[selectedItem.category] === undefined) {
          newScopeCategories[selectedItem.category] = [];
        }
        newScopeCategories[selectedItem.category].push(selectedItem);
      }
    });
    setScopeTotal(total);
    setScopeCategories(newScopeCategories);
  }, [selectedItems, scopeSheetsAddAll]);

  function onSheetSelectClick(id: number) {
    const newScopeSheets = scopeSheetsAddAll.map(value => {
      value.selected = value.id === id;
      return value;
    });
    setScopeSheetsAddAll(newScopeSheets);
  }


  return (
    <div className="w-100 pt-3">
      <SheetNameMenu scopeSheets={scopeSheetsAddAll} onSheetSelectClick={onSheetSelectClick} outlineNotSelected={true} />
      {Object.keys(scopeCategories).map((category) => (
        <div key={category}>
          <div className={classes.categoryHeader}>{category}</div>
          {scopeCategories[category].map((selectedItem) => (
            <div key={selectedItem.id} className={classes.workScopeRow}>
              <div className={classes.field}>
                {selectedItem.codePart1}
                {selectedItem.codePart2}
              </div>
              <div className={classes.field}>{selectedItem.description}</div>
              <div className={classes.fieldRight}>
                {selectedItem.quantity} x{selectedItem.unit}
                {selectedItem.rate && <span> @{currencyFormat.format(parseFloat(selectedItem.rate))} </span>}
              </div>
              <div className={classes.fieldRight}>
                {selectedItem.rate && (
                  <span>{currencyFormat.format(selectedItem.quantity * parseFloat(selectedItem.rate))}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
      <hr className="w-100" />
      <div className={classes.workScopeRow}>
        <div className={classes.workScopeRow}>Total</div>
        <div className={classes.workScopeRow} />
        <div className={classes.workScopeRow} />
        <div className={classes.fieldRight}>{currencyFormat.format(scopeTotal)}</div>
      </div>
    </div>
  );
};

const WorkScopeViewMemo = memo(WorkScopeView, areEqual);

export { WorkScopeViewMemo as WorkScopeView };
