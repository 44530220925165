export type ExternalAtmosphericLog = {
  id: number;
  createdAt: string;
  updatedAt?: string;
  relativeHumidity: number;
  temperature: number;
  pressure: number;
  windSpeed: number;
  photo: any; // TODO: add photo type
  creator: Creator;
};

export type InternalAtmosphericLog = {
  id: number;
  createdAt: string;
  updatedAt?: string;
  relativeHumidity: number;
  temperature: number;
  dewPoint: number;
  gpp: number;
  emptyEntry?: boolean; // to make it easier to handle empty inlet entries
  photo: any; // TODO: add photo type
  creator: Creator;
};

type Creator = {
  id: number;
  name: string;
};

export type InternalAtmosphericLogGroup = {
  inlet: InternalAtmosphericLog;
  outlet?: InternalAtmosphericLog;
};

export enum atmosphericLogType {
  External,
  Internal,
}

export type EquipmentInventoryEntry = {
  id: string;
  name: string;
  is_standard: boolean;
};

export type RoomEquipment = {
  id: number;
  name: string;
  display_name: string;
  equipment_id: number;
  room_id: number;
  is_standard: boolean;
  quantity: number;
  duration: number;
  date_in: string;
  date_out: string;
};
