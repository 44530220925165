import React, { memo } from 'react';
import { Icon, RoomIcon } from 'Components/Icons';

import { Modal } from 'Components/Modal';

import { PurpleButton } from 'Components/Button';
import { EquipmentInventoryEntry } from 'Containers/RocketDry/types';
import { trimAndToLowerCase } from 'Utils/helpers';

import { areEqual } from 'Utils/equalityChecks';
import classes from './addRoomEquipmentModal.module.css';
import { SelectEquipment } from './SelectEquipment';

interface Props {
  // modal variables
  isOpen: boolean;

  //
  equipmentList: EquipmentInventoryEntry[];
  selectedEquipmentIDs: string[];

  // modal functions
  onSaveButtonClick: (e: any) => void;
  closeModal: (e: any) => void;

  onClickEquipment: (e: any) => void;
}

function AddRoomEquipmentModal({
  isOpen,
  equipmentList,
  selectedEquipmentIDs,
  onSaveButtonClick,
  closeModal,
  onClickEquipment,
}: Props) {
  return (
    <Modal
      title="Add Equipment"
      classes={classes}
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={closeModal}
    >
      <div className="d-flex flex-column w-100">
        <div className={`list-group ${classes.listContainer}`}>
          {equipmentList.map((equipment) => (
            <SelectEquipment
              key={equipment.id}
              name={equipment.name}
              icon={
                equipment.is_standard ? (
                  <Icon type={trimAndToLowerCase(equipment.name)} />
                ) : (
                  <RoomIcon type="customroom" />
                )
              }
              selected={selectedEquipmentIDs.includes(equipment.id)}
              onClick={() => onClickEquipment(equipment.id)}
            />
          ))}
        </div>
        <div className={`d-flex mt-4 modal-footer ${classes.modalFooter}`}>
          <PurpleButton onClick={closeModal} className={classes.cancelButton}>
            Cancel
          </PurpleButton>
          <PurpleButton onClick={onSaveButtonClick} className={classes.saveButton}>
            Save
          </PurpleButton>
        </div>
      </div>
    </Modal>
  );
}

const AddRoomEquipmentModalMemo = memo(AddRoomEquipmentModal, areEqual);

export { AddRoomEquipmentModalMemo as AddRoomEquipmentModal };
