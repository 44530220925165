import React, { memo, useCallback } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch } from 'react-redux';

import { deleteEquipmentInRoom } from 'Containers/RocketDry/actions';

import { DeleteRoomEquipmentModal } from 'Components/RocketDry';

import { useRocketDryFunctions } from 'Context/RocketDry';

function DeleteRoomEquipmentModalContainer() {
  const dispatch = useDispatch();

  const {
    selectedEquipment,
    setEquipmentDeletedRoomId,
    isDeleteEquipmentModalOpen,
    setIsDeleteEquipmentModalOpen,
  }: any = useRocketDryFunctions();

  const onCloseClick = useCallback(async () => {
    setIsDeleteEquipmentModalOpen(false);
  }, [selectedEquipment]);

  const onDeleteButtonClick = useCallback(async () => {
    await dispatch(deleteEquipmentInRoom(selectedEquipment.id));
    setEquipmentDeletedRoomId(selectedEquipment.room_id);
    setIsDeleteEquipmentModalOpen(false);
  }, [selectedEquipment]);

  return (
    <DeleteRoomEquipmentModal
      isOpen={isDeleteEquipmentModalOpen}
      equipment={selectedEquipment}
      modalCloseClick={onCloseClick}
      onDeleteButtonClick={onDeleteButtonClick}
    />
  );
}

const DeleteRoomEquipmentModalContainerMemo = memo(DeleteRoomEquipmentModalContainer, areEqual);

export { DeleteRoomEquipmentModalContainerMemo as DeleteRoomEquipmentModal };
