import React, { memo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AddInternalAtmosphericLogModal } from 'Components/RocketDry';

import { useRocketDryFunctions } from 'Context/RocketDry';
import { addRoomAtmosphericLogs } from 'Containers/RocketDry/actions';

const numberInputRegex = /[^\-.0-9]/g;

function checkValidRelativeHumidity(value: number): boolean {
  return value >= 0 && value <= 100;
}

function checkValidTemperature(value: number): boolean {
  return value >= -999 && value <= 999;
}

function checkValidDewPoint(value: number): boolean {
  return value >= -999 && value <= 999;
}

function checkValidGPP(value: number): boolean {
  return value >= 0 && value <= 999;
}

function AddInternalAtmosphericLogModalContainer() {
  const dispatch = useDispatch();

  const {
    isAddInternalAtmosphericLogModalOpen,
    setIsAddInternalAtmosphericLogModalOpen,
    selectedRoomId,
    selectedRoomArea,
    setInternalAtmosphericLogCreated,
  }: any = useRocketDryFunctions();

  const [inletRelativeHumidity, setInletRelativeHumidity] = useState('');
  const [inletTemperature, setInletTemperature] = useState('');
  const [inletDewPoint, setInletDewPoint] = useState('');
  const [inletGPP, setInletGPP] = useState('');

  const [outletRelativeHumidity, setOutletRelativeHumidity] = useState('');
  const [outletTemperature, setOutletTemperature] = useState('');
  const [outletDewPoint, setOutletDewPoint] = useState('');
  const [outletGPP, setOutletGPP] = useState('');

  const [formErrors, setFormErrors] = useState({});

  const onChangeInletRelativeHumidity = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setInletRelativeHumidity(filtered);
  }, []);

  const onChangeInletTemperature = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setInletTemperature(filtered);
  }, []);

  const onChangeInletDewPoint = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setInletDewPoint(filtered);
  }, []);

  const onChangeInletGPP = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setInletGPP(filtered);
  }, []);

  const onChangeOutletRelativeHumidity = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setOutletRelativeHumidity(filtered);
  }, []);

  const onChangeOutletTemperature = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setOutletTemperature(filtered);
  }, []);

  const onChangeOutletDewPoint = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setOutletDewPoint(filtered);
  }, []);

  const onChangeOutletGPP = useCallback(({ target: { value } }) => {
    const filtered = value.replace(numberInputRegex, '');
    setOutletGPP(filtered);
  }, []);

  const onCloseModal = useCallback(() => {
    setIsAddInternalAtmosphericLogModalOpen(false);
    setInletRelativeHumidity('');
    setInletTemperature('');
    setInletDewPoint('');
    setInletGPP('');
    setOutletRelativeHumidity('');
    setOutletTemperature('');
    setOutletDewPoint('');
    setOutletGPP('');
    setFormErrors({});
  }, []);

  const onSaveInternalButtonClick = useCallback(async () => {
    if (selectedRoomId) {
      // The process on iOS is to create both inlet and outlet logs even if one of them isn't filled out
      // this allows an easier way to update them later
      // This means we need to validate the values beforehand.
      const canCreate =
        inletRelativeHumidity ||
        inletTemperature ||
        inletDewPoint ||
        inletGPP ||
        outletRelativeHumidity ||
        outletTemperature ||
        outletDewPoint ||
        outletGPP;
      if (canCreate) {
        const errors: any = {};
        if (inletRelativeHumidity && !checkValidRelativeHumidity(parseFloat(inletRelativeHumidity))) {
          errors.inletRelativeHumidity = 'Relative humidity should be between 0 and 100.';
        }
        if (inletTemperature && !checkValidTemperature(parseFloat(inletTemperature))) {
          errors.inletTemperature = 'Temperature should be between -999 and 999.';
        }
        if (inletDewPoint && !checkValidDewPoint(parseFloat(inletDewPoint))) {
          errors.inletDewPoint = 'Dew point should be between -999 and 999.';
        }
        if (inletGPP && !checkValidGPP(parseFloat(inletGPP))) {
          errors.inletGPP = 'GPP should be between 0 and 999.';
        }

        if (outletRelativeHumidity && !checkValidRelativeHumidity(parseFloat(outletRelativeHumidity))) {
          errors.outletRelativeHumidity = 'Relative humidity should be between 0 and 100.';
        }
        if (outletTemperature && !checkValidTemperature(parseFloat(outletTemperature))) {
          errors.outletTemperature = 'Temperature should be between -999 and 999.';
        }
        if (outletDewPoint && !checkValidDewPoint(parseFloat(outletDewPoint))) {
          errors.outletDewPoint = 'Dew point should be between -999 and 999.';
        }
        if (outletGPP && !checkValidGPP(parseFloat(outletGPP))) {
          errors.outletGPP = 'GPP should be between 0 and 999.';
        }
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
          const addInletLogResponse: any = await dispatch(
            addRoomAtmosphericLogs(
              selectedRoomId,
              inletRelativeHumidity,
              inletTemperature,
              inletDewPoint,
              inletGPP,
              selectedRoomArea
            )
          );

          if (addInletLogResponse?.data) {
            const { data } = addInletLogResponse;
            const { id: inletId } = data;
            await dispatch(
              addRoomAtmosphericLogs(
                selectedRoomId,
                outletRelativeHumidity,
                outletTemperature,
                outletDewPoint,
                outletGPP,
                selectedRoomArea,
                inletId
              )
            );
            setInternalAtmosphericLogCreated(true);
            onCloseModal();
          }
        }
      }
    }
  }, [
    inletRelativeHumidity,
    inletTemperature,
    inletDewPoint,
    inletGPP,
    outletRelativeHumidity,
    outletTemperature,
    outletDewPoint,
    outletGPP,
    selectedRoomId,
    selectedRoomArea,
  ]);

  return (
    <AddInternalAtmosphericLogModal
      isOpen={isAddInternalAtmosphericLogModalOpen}
      closeModal={onCloseModal}
      inletRelativeHumidity={inletRelativeHumidity}
      inletTemperature={inletTemperature}
      inletDewPoint={inletDewPoint}
      inletGPP={inletGPP}
      outletRelativeHumidity={outletRelativeHumidity}
      outletTemperature={outletTemperature}
      outletDewPoint={outletDewPoint}
      outletGPP={outletGPP}
      formErrors={formErrors}
      onSaveInternalButtonClick={onSaveInternalButtonClick}
      onChangeInletRelativeHumidity={onChangeInletRelativeHumidity}
      onChangeInletTemperature={onChangeInletTemperature}
      onChangeInletDewPoint={onChangeInletDewPoint}
      onChangeInletGPP={onChangeInletGPP}
      onChangeOutletRelativeHumidity={onChangeOutletRelativeHumidity}
      onChangeOutletTemperature={onChangeOutletTemperature}
      onChangeOutletDewPoint={onChangeOutletDewPoint}
      onChangeOutletGPP={onChangeOutletGPP}
    />
  );
}

const AddInternalAtmosphericLogModalMemo = memo(AddInternalAtmosphericLogModalContainer);

export { AddInternalAtmosphericLogModalMemo as AddInternalAtmosphericLogModal };
