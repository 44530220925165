import React, { memo } from 'react';
import { Icon } from 'Components/Icons';

import { Modal } from 'Components/Modal';

import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { PurpleButton } from 'Components/Button';
import { ValidateBackGround } from 'Components/Validation';

import formClasses from 'Themes/form/form.module.css';
import classes from './addLogModal.module.css';

interface Props {
  // modal variables
  isOpen: boolean;
  formErrors: any;

  // atmospheric log variables
  inletRelativeHumidity?: string;
  inletTemperature?: string;
  inletDewPoint?: string;
  inletGPP?: string;
  outletRelativeHumidity?: string;
  outletTemperature?: string;
  outletDewPoint?: string;
  outletGPP?: string;

  // modal functions
  onSaveInternalButtonClick: (e: any) => void;
  closeModal: (e: any) => void;

  // atmospheric log functions
  onChangeInletRelativeHumidity: (e: any) => void;
  onChangeInletTemperature: (e: any) => void;
  onChangeInletDewPoint: (e: any) => void;
  onChangeInletGPP: (e: any) => void;
  onChangeOutletRelativeHumidity: (e: any) => void;
  onChangeOutletTemperature: (e: any) => void;
  onChangeOutletDewPoint: (e: any) => void;
  onChangeOutletGPP: (e: any) => void;
}

function AddInternalAtmosphericLogModal({
  isOpen,
  formErrors,
  inletRelativeHumidity,
  inletTemperature,
  inletDewPoint,
  inletGPP,
  outletRelativeHumidity,
  outletTemperature,
  outletDewPoint,
  outletGPP,
  onSaveInternalButtonClick,
  closeModal,
  onChangeInletRelativeHumidity,
  onChangeInletTemperature,
  onChangeInletDewPoint,
  onChangeInletGPP,
  onChangeOutletRelativeHumidity,
  onChangeOutletTemperature,
  onChangeOutletDewPoint,
  onChangeOutletGPP,
}: Props) {
  return (
    <Modal title="Add Internal Atmospheric Reading" isOpen={isOpen} classes={{ modalDialog: classes.modalOverride }}>
      <h5 className={classes.heading}>
        Add Internal Atmospheric Reading <Icon type="close" className={classes.closeModal} onClick={closeModal} />
      </h5>
      <form
        className={`requires-validation g-3 ${formClasses.formBase} ${classes.container}`}
        action="#"
        onSubmit={() => {}}
      >
        <div className={classes.formWrapper}>
          <h2>Inlet</h2>
          <div>
            <ValidateBackGround isValid={!formErrors?.inletRelativeHumidity?.length}>
              <Label ariaLabel="Relative Humidity" className={formClasses.label} htmlFor="relativeHumidity">
                Relative Humidity (%)
              </Label>
              <TextBox
                value={inletRelativeHumidity}
                name="relativeHumidity"
                type="text" // number type interferes with the validation code while still allowing letters and other invalid input
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.inletRelativeHumidity?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.inletRelativeHumidity?.length ? 'is-invalid' : ''}`}
                placeholder=""
                ariaLabel="Please enter the relative humidity"
                onChange={onChangeInletRelativeHumidity}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>
                {formErrors.inletRelativeHumidity}
              </div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.inletTemperature?.length}>
              <Label ariaLabel="Temperature" className={formClasses.label} htmlFor="temperature">
                Temperature (°F)
              </Label>
              <TextBox
                value={inletTemperature}
                name="temperature"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.inletTemperature?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.inletTemperature?.length ? 'is-invalid' : ''}`}
                placeholder=""
                ariaLabel="Please enter the temperature"
                onChange={onChangeInletTemperature}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>
                {formErrors.inletTemperature}
              </div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.inletDewPoint?.length}>
              <Label ariaLabel="Dew Point" className={formClasses.label} htmlFor="dewPoint">
                Dew Point (°F)
              </Label>
              <TextBox
                value={inletDewPoint}
                name="dewPoint"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.inletDewPoint?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.inletDewPoint?.length ? 'is-invalid' : ''}`}
                placeholder=""
                ariaLabel="Please enter the dew point"
                onChange={onChangeInletDewPoint}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>{formErrors.inletDewPoint}</div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.inletGPP?.length}>
              <Label ariaLabel="GPP" className={formClasses.label} htmlFor="gpp">
                GPP
              </Label>
              <TextBox
                value={inletGPP}
                name="gpp"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.inletGPP?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.inletGPP?.length ? 'is-invalid' : ''}`}
                placeholder=""
                ariaLabel="Please enter the GPP"
                onChange={onChangeInletGPP}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>{formErrors.inletGPP}</div>
            </ValidateBackGround>
          </div>

          <h2>Outlet</h2>
          <div>
            <ValidateBackGround isValid={!formErrors?.outletRelativeHumidity?.length}>
              <Label ariaLabel="Relative Humidity" className={formClasses.label} htmlFor="relativeHumidity">
                Relative Humidity (%)
              </Label>
              <TextBox
                value={outletRelativeHumidity}
                name="relativeHumidity"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.outletRelativeHumidity?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.outletRelativeHumidity?.length ? 'is-invalid' : ''}`}
                placeholder=""
                ariaLabel="Please enter the relative humidity"
                onChange={onChangeOutletRelativeHumidity}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>
                {formErrors.outletRelativeHumidity}
              </div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.outletTemperature?.length}>
              <Label ariaLabel="Temperature" className={formClasses.label} htmlFor="temperature">
                Temperature (°F)
              </Label>
              <TextBox
                value={outletTemperature}
                name="temperature"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.outletTemperature?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.outletTemperature?.length ? 'is-invalid' : ''}`}
                placeholder=""
                ariaLabel="Please enter the temperature"
                onChange={onChangeOutletTemperature}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>
                {formErrors.outletTemperature}
              </div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.outletDewPoint?.length}>
              <Label ariaLabel="Dew Point" className={formClasses.label} htmlFor="dewPoint">
                Dew Point (°F)
              </Label>
              <TextBox
                value={outletDewPoint}
                name="dewPoint"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.outletDewPoint?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.outletDewPoint?.length ? 'is-invalid' : ''}`}
                placeholder=""
                ariaLabel="Please enter the dew point"
                onChange={onChangeOutletDewPoint}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>{formErrors.outletDewPoint}</div>
            </ValidateBackGround>
          </div>
          <div>
            <ValidateBackGround isValid={!formErrors?.outletGPP?.length}>
              <Label ariaLabel="GPP" className={formClasses.label} htmlFor="gpp">
                GPP
              </Label>
              <TextBox
                value={outletGPP}
                name="gpp"
                type="text"
                className={`mb-0 pb-0 ${formClasses.validateField} ${
                  formErrors?.outletGPP?.length ? formClasses.invalidField : formClasses.validField
                } ${formErrors?.outletGPP?.length ? 'is-invalid' : ''}`}
                placeholder=""
                ariaLabel="Please enter the GPP"
                onChange={onChangeOutletGPP}
                autoComplete="off"
              />
              <div className={`${formClasses.invalidFieldFeedback} invalid-feedback`}>{formErrors.outletGPP}</div>
            </ValidateBackGround>
          </div>
        </div>
        <div className={`modal-footer ${classes.modalFooter}`}>
          <PurpleButton onClick={closeModal} className={classes.cancelButton}>
            Cancel
          </PurpleButton>
          <PurpleButton onClick={onSaveInternalButtonClick} className={classes.saveButton}>
            Save
          </PurpleButton>
        </div>
      </form>
    </Modal>
  );
}

const AddInternalAtmosphericLogModalMemo = memo(AddInternalAtmosphericLogModal);

export { AddInternalAtmosphericLogModalMemo as AddInternalAtmosphericLogModal };
