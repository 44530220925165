import axios from 'axios';
import type { UserModel } from 'Containers/User/Models/UserModel';
import { addressCreateWithAlias } from 'Containers/Address/actions';
import { updateUser } from 'Containers/User/actions';
import { formatAsCalendarDate } from 'Utils/helpers';
import { FETCHING_ST_PROJECTS } from 'Containers/Projects/actions';

import { Api } from './api';

async function convertJobsToProjects(jobs: any[], user: UserModel, dispatch) {
  try {
    // Using Array as a queue for job ids to be reassigned to the project as an alias
    const jobQueue = [];

    // Getting all the locations of the current ST jobs
    const locationIds = jobs.map((job) => {
      jobQueue.push({ id: job.jobNumber, locationId: job.locationId, summary: job.summary });
      return job.locationId;
    });

    /*
      *** EXAMPLE OF jobQueue ***
      jobQueue = [
        {id: 1, locationId: 1}
        {id: 2, locationId: 1}
        {id: 3, locationId: 2}
      ]
    */

    if (locationIds.length) {
      const locationsData = await axios.get(
        `${import.meta.env.VITE_APP_ST_API}locations?tenantId=${user.st_tenant_id}&locationId=${locationIds.join()}`,
        {
          headers: {
            token: localStorage.getItem('st-token'),
            'access-key': import.meta.env.VITE_APP_LAMBDA_ACCESS_KEY,
          },
        }
      );
      const locations = locationsData.data.data.data;
      /*
        *** EXAMPLE OF locations ***
        locations = [
          {id: 1, ...location details}
          {id: 2, ...location details}
        ]
      */

      // Create an address / project with an alias
      jobQueue.forEach((job, idx) => {
        const foundLocationIndex = locations.findIndex((location) => job.locationId === location.id);
        if (foundLocationIndex !== -1) {
          dispatch(
            addressCreateWithAlias(
              {
                google_places_id: null,
                country: locations[foundLocationIndex].address.country,
                state: locations[foundLocationIndex].address.state,
                city: locations[foundLocationIndex].address.city,
                zip: locations[foundLocationIndex].address.zip,
                address: locations[foundLocationIndex].address.street,
                address_2: locations[foundLocationIndex].address.unit,
                latitude: locations[foundLocationIndex].address.latitude.toString(),
                longitude: locations[foundLocationIndex].address.longitude.toString(),
              },
              user.companies[0]?.id,
              '1',
              job.id,
              user,
              locations.length - 1,
              idx,
              job.summary
            )
          );
        }
      });

      // update st_technician_date (last synced day) to today
      let date = new Date();
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      const formattedDate = formatAsCalendarDate(date);

      dispatch(
        updateUser(`users/${user.id}`, 'put', {
          st_technician_date: formattedDate,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        })
      );
    }

    if (!locationIds.length) dispatch({ type: FETCHING_ST_PROJECTS, payload: false });
  } catch (err) {
    dispatch({ type: FETCHING_ST_PROJECTS, payload: false });
    // handle err
  }
}

export default async function getSTJobs(user: UserModel, dispatch) {
  try {
    dispatch({
      type: FETCHING_ST_PROJECTS,
      payload: true,
    });

    // Get all ST projects for the technician through the Lambda
    const date = user.st_technician_date ? user.st_technician_date : '2023-09-20';
    const stJobs = await axios.get(
      `${import.meta.env.VITE_APP_ST_API}jobs?tenantId=${user.st_tenant_id}&technicianId=${
        user.st_technician_id
      }&date=${date}`,
      {
        headers: {
          token: localStorage.getItem('st-token'),
          'access-key': import.meta.env.VITE_APP_LAMBDA_ACCESS_KEY,
        },
      }
    );

    if (stJobs.status !== 200) {
      throw new Error('Something went wrong while fetching jobs from ServiceTitan');
    }

    localStorage.setItem('st-token', stJobs.data.token);

    const stJobIds = stJobs.data.data.data.map((job) => job.id);

    //  check for jobs that haven't already been created
    const checkProjectsResponse = await Api.get(`companies/${user.companies[0].id}/service-titan/check-projects`, {
      params: { project_ides: stJobIds },
    });
    if (checkProjectsResponse.status !== 200) {
      throw new Error('Error checking for ServiceTitan jobs that need to be created');
    }
    const missingJobIds = checkProjectsResponse.data.missing_ids;

    if (missingJobIds.length > 0) {
      const filteredJobs = stJobs.data.data.data.filter((job) => missingJobIds.includes(job.id.toString()));
      convertJobsToProjects(filteredJobs, user, dispatch);
    } else {
      dispatch({ type: FETCHING_ST_PROJECTS, payload: false });
    }
  } catch (err) {
    // handle error
  }
}
